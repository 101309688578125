import * as React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useState, useEffect } from "react";
import copyOutline from "@iconify/icons-eva/copy-outline";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CONTRACTADDRESS from "../config/contractData";
import dayjs from "dayjs";
import copy from "copy-to-clipboard";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers";
import link2Outline from "@iconify/icons-eva/link-2-outline";
import { Icon } from "@iconify/react";
import IGOContractAbi from "../abi/igo.json";
import { IGOByteCode } from "src/contractData/byteCode";
import styled from "styled-components";
import PlusCircleFill from "@iconify/icons-eva/plus-circle-fill";
import CloseCircleFill from "@iconify/icons-eva/close-circle-fill";
import EditOutline from "@iconify/icons-eva/edit-outline";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
// import AdapterDayjs from "@mui/lab/AdapterDayjs";
import {
  compactAddress,
  ordinal_suffix_of,
  TimeStampToDateString,
  utcToLocalTimeStamp,
} from "src/contractData/function";
import {
  getContractInstanceAbi,
  convertToWei,
  getTokenInstance,
} from "../helper/contractHelper";
import CONSTANT from "../constant";
// material
import { web3Services } from "../services/web3.service";
import { toaster } from "../utils/toast";
import {
  Card,
  Stack,
  TextField,
  Container,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import moment from "moment";
import * as XLSX from "xlsx";
import { services } from "../services/index";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

// import { update } from 'lodash-es';
import { web3 } from "src/web3";
// import ipfs from "../config/ipfs";
import axios from "axios";
import { useParams } from "react-router-dom";
import withRouter from "../components/getParamID";
import readFile from "src/helper/helperFunction";

// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePi
class Claim extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      web3Data: {
        isLoggedIn: false,
        accounts: [],
      },
      ///////
      allIgoList: [],
      //////
      paymentTokenAddress: "",
      paymentTokenSymbol: "",
      igoName: "",
      igoTokenName: "",
      igoTokenAddress: "",
      igoTokenSymbol: "",
      igoTokenDecimal: "",
      targetPoolRaise: "",
      imageURL: "",
      accessType: "",
      igoDescription: "",
      isPaymentTokenNative: false,
      networkType: "",
      network: "",
      socialLinks: {},
      ownerAddress: "",
      price: 0,

      ///////////////////////////////////////////////////////////////////////////////////////////
      igoUsersWalletAddress: [],
      igoUsersTiers: [],
      igoUsersData: [],
      isIGOPhaseEdit: 0,
      phase: 0,
      id: "",
      // contractAddress: "",
      content: "",

      poolType: "",
      participants: "",

      decimal: "",

      descrp: "",
      description: "",

      token_distribution_date: dayjs(),
      editId: "",
      isEdit: false,
      addSocialModal: false,
      addPhaseModal: false,
      fileError: false,
      isLoading: false,
      invtData: [],
      error: "",

      twitter: "",
      git: "",
      telegram: "",
      reddit: "",
      medium: "",
      browser_web: "",
      youtube: "",
      instagram: "",
      discord: "",
      white_paper: "",
      facebook: "",

      phases: [],
      poolStatus: "",
      ///////////

      phaseName: "",

      phaseContractAddress: "",
      minUserAllocation: "",
      maxUserAllocation: "",
      addPhase: false,
      start_date: dayjs(),
      end_date: dayjs(),
      phasePoolRaise: 0,
      igoTokenSupply: 0,
      tokenDistributionDate: dayjs(),
      tiers: "",
      maxTierCap: "",
      minUserCap: [],
      maxUserCap: [],
      tierUsers: [],
      //////////

      currPhaseDetails: {},
      currPhaseId: undefined,
      addUpdatePhaseModal: false,
      weights: [1.2, 2, 5.5, 12, 19, 26, 70, 150, 325],
      tweight: 610.7,
      contractInstance: null,
      allIGOName: [],
    };
  }
  fetchIgo = async () => {
    console.log("api called");
    let url1 = axios.get(
      `https://raiserapi.blocsys.com/api/v1/IGOPools/completed-list`
    );
    let url2 = axios.get(
      `https://raiserapi.blocsys.com/api/v1/IGOPools/featured-list`
    );
    let url3 = axios.get(
      `https://raiserapi.blocsys.com/api/v1/IGOPools/upcoming-list`
    );
    Promise.all([url1, url2, url3]).then(([res1, res2, res3]) => {
      this.setState({
        allIgoList: [...res1.data.data, ...res2.data.data, ...res3.data.data],
      });
    });
  };

  componentDidMount = async () => {
    await this.connectMetamask();
    await this.fetchIgo();
    let id = this.props.params.id;
    if (!this.props.editIgo) {
      const url = `https://raiserapi.blocsys.com/api/v1/apply-proj/list?approved=true`;
      const sendRequest = await services.Get(url);
      if (sendRequest.status === 200) {
        // this.setState({ isEdit: true, editId: id });
        const data = await sendRequest.data.data;
        this.setState({ allIGOName: data });
      }
    }
    if (this.props.editIgo) {
      const url = `https://raiserapi.blocsys.com/api/v1/IGOPools/get-igo/${id}`;

      const headers = {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      };

      const sendRequest = await axios.get(url);
      if (sendRequest.status === 200) {
        // this.setState({ isEdit: true, editId: id });
        const data = sendRequest.data.data;
        console.log("dataaa", data);

        this.setState({
          id: data._id,
          igoName: data.igoName,
          igoTokenName: data.igoTokenName,
          igoTokenAddress: data.igoTokenAddress,
          igoTokenSymbol: data.igoTokenSymbol,
          igoTokenDecimal: data.igoTokenDecimal,
          paymentTokenAddress: data.paymentTokenAddress,
          paymentTokenSymbol: data.paymentTokenSymbol,
          targetPoolRaise: data.targetPoolRaise,
          imageURL: data.imageURL,
          accessType: data.accessType,
          descrp: data.igoDescription,
          isPaymentTokenNative: data.isPaymentTokenNative,
          networkType: data.networkType,
          network: data.network,
          tokenDistributionDate: TimeStampToDateString(
            data.tokenDistributionDate
          ),

          telegram: data.socialLinks.telegram,
          twitter: data.socialLinks.twitter,
          git: data.socialLinks.git,
          reddit: data.socialLinks.reddit,
          medium: data.socialLinks.medium,
          browser_web: data.socialLinks.browser_web,
          youtube: data.socialLinks.youtube,
          instagram: data.socialLinks.instagram,
          discord: data.socialLinks.discord,
          white_paper: data.socialLinks.white_paper,
          facebook: data.socialLinks.facebook,

          ownerAddress: data.ownerAddress,
          price: data.price,
          phases: data.phases,
        });
      } else {
        console.log("err");
      }
    }
  };
  onTransactionError(error) {
    if (error.code === 4001) {
      this.genrateError("Transaction cancelled by user");
    } else if (error.code === -32602) {
      this.genrateError("Wrong parameters");
    } else if (error.code === -32603) {
      this.genrateError("Internal Error");
    } else if (error.code === -32002) {
      this.genrateError("Pending Previous request");
    } else {
      this.genrateError("Transaction has been reverted");
    }
  }

  genrateError = (text) => {
    return toaster(`${text}`, "error");
  };

  genrateSuccess = (text) => {
    return toaster(`${text}`, "success");
  };

  processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    this.setState({ claimData: list });
  };
  readFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      this.processData(data);
    };
    reader.readAsBinaryString(file);
  };
  getDecimals = async () => {
    const tokenInstance = await getTokenInstance(this.state.igoTokenAddress);
    const decimal = await tokenInstance.methods.decimals().call();
    this.setState({ decimal });
  };

  precisedAmount = (amount) => {
    if (+this.state.decimal === 18) {
      return convertToWei(amount.toString());
    } else {
      let pow = (amount * Math.pow(10, +this.state.decimal)).toFixed(0);
      return pow.toString();
    }
  };

  newSubmit = async (e) => {
    e.preventDefault();

    let data = {};
    if (this.props.editIgo) data._id = this.state.id;

    data.igoName = this.state.igoName;
    data.igoTokenName = this.state.igoTokenName;
    data.igoTokenAddress = this.state.igoTokenAddress;
    data.igoTokenSymbol = this.state.igoTokenSymbol;
    data.igoTokenDecimal = this.state.igoTokenDecimal;
    data.paymentTokenAddress = this.state.paymentTokenAddress;
    data.paymentTokenSymbol = this.state.paymentTokenSymbol;
    data.targetPoolRaise = this.state.targetPoolRaise;
    data.imageURL = this.state.imageURL;
    data.accessType = this.state.accessType;
    data.igoDescription = this.state.igoDescription;
    data.isPaymentTokenNative = this.state.isPaymentTokenNative;
    data.networkType = this.state.networkType;
    data.network = this.state.network;
    data.tokenDistributionDate = dayjs(this.state.tokenDistributionDate).unix();
    data.socialLinks = {
      telegram: this.state.telegram,
      twitter: this.state.twitter,
      git: this.state.git,
      reddit: this.state.reddit,
      medium: this.state.medium,
      browser_web: this.state.browser_web,
      youtube: this.state.youtube,
      instagram: this.state.instagram,
      discord: this.state.discord,
      white_paper: this.state.white_paper,
      facebook: this.state.facebook,
    };
    data.ownerAddress = this.state.ownerAddress;
    data.price = this.state.price;
    data.phases = this.state.phases;

    try {
      const url = this.props.editIgo
        ? `https://raiserapi.blocsys.com/api/v1/IGOPools/igo-edit`
        : `https://raiserapi.blocsys.com/api/v1/IGOPools/createIGO`;

      const headers = {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      };
      const sendRequest = await axios.post(url, data);
      console.log("Data", data, "sendRequest", sendRequest);

      if (sendRequest.status === 200) {
        this.setState({ showLoader: false });
        this.genrateSuccess("Snapshot data is saved");
        return sendRequest.data;
      }
    } catch (err) {
      this.setState({ showLoader: false });
      this.genrateError(
        `${err.message ? err.message : "Something went wrong "}`
      );
    }
  };

  // connect metamask
  connectMetamask = async () => {
    try {
      const getAcountDetails = await web3Services.enableMetamask();
      // const chainId = await window.ethereum.request({ method: "eth_chainId" });
      const chainId = await web3.eth.net.getId();
      this.setState({ web3Data: getAcountDetails, network: chainId });
      // this.getNetworkId(chainId);
    } catch (err) {
      console.log("error is:", err);
    }
  };
  splitTierData = (data) => {
    return data.split(",");
  };

  pauseUnpauseIgo = async () => {
    this.setState({ showLoader: true });

    try {
      let hash = "";
      let currMethod = "pause";
      const contractInstance = getContractInstanceAbi(
        this.state.phases[this.state.phase].phaseContractAddress,
        "igo"
      );
      const addToContract = await contractInstance.methods[currMethod]()
        .send({ from: this.state.web3Data.accounts[0] })
        .on("transactionHash", (_hash) => {
          hash = _hash;
        })
        .on("receipt", (receipt) => {
          this.genrateSuccess("Selected phase rewards added to blockchain. ");
          this.setState({ showLoader: false });
        });
    } catch (err) {
      this.setState({ showLoader: false });
      console.log(err);
    }
  };
  deployContract = async () => {
    const { currPhaseDetails } = this.state;

    if (!this.state.paymentTokenAddress)
      return this.genrateError("Please enter the Token Address first");
    if (
      // dayjs(currPhaseDetails.start_date).unix() <= Math.round(Date.now() / 1000)
      currPhaseDetails.start_date <= Math.round(Date.now() / 1000)
    )
      return this.genrateError(
        "Start date and time must be greater than current date and time"
      );
    if (
      currPhaseDetails.start_date >= currPhaseDetails.end_date
      // dayjs(currPhaseDetails.start_date).unix() >=
      // dayjs(currPhaseDetails.end_date).unix()
    )
      return this.genrateError(
        "End date must be greater than start date and time"
      );
    if (
      currPhaseDetails?.maxUserAllocation === undefined ||
      currPhaseDetails?.maxUserAllocation === ""
    )
      return this.genrateError("Please enter max allocation");
    if (this.state.igoName == "" || this.state.ownerAddress == "")
      return this.genrateError("Input Required");
    this.setState({ showLoader: true });
    const curr_abi = await IGOContractAbi;
    const curr_byteCode = await IGOByteCode;
    let deploy_contract = await new web3.eth.Contract(curr_abi);
    const initialValue = 0;

    let totalTierUsers = await +currPhaseDetails?.tierUsers?.reduce(
      (accumulator, currentValue) => Number(accumulator) + Number(currentValue),
      initialValue
    );
    let args = [
      this.state.igoName ? this.state.igoName : "",
      web3.utils.toWei(currPhaseDetails?.phasePoolRaise),
      currPhaseDetails?.start_date,
      // dayjs(currPhaseDetails?.start_date).unix(),
      currPhaseDetails?.end_date,
      // dayjs(currPhaseDetails?.end_date).unix(),
      currPhaseDetails?.no_of_tiers ? currPhaseDetails?.no_of_tiers : 9,
      this.state.ownerAddress,
      this.state.paymentTokenAddress,
      currPhaseDetails?.tierUsers ? totalTierUsers : 0,
      currPhaseDetails?.phaseName === "PHASE_ONE" ? 1 : 2,
    ];
    console.log(
      "args",
      args,
      "currPhaseDetails?.tierUsers",
      currPhaseDetails?.tierUsers
    );
    await deploy_contract
      .deploy({
        data: curr_byteCode,
        arguments: args,
      })
      .send({
        from: this.state.web3Data.accounts[0],
      })
      .on("error", (error) => {
        console.log("error", error);
        this.setState({ showLoader: false });
        return false;
      })
      .on("transactionHash", (transactionHash) => {
        console.log("transactionHash", transactionHash);
        this.setState({
          currPhaseDetails: {
            ...currPhaseDetails,
            contractHash: transactionHash,
          },
        });
        this.genrateSuccess("Transaction Processing");
      })
      .on("receipt", (receipt) => {
        this.genrateSuccess(
          "Linear Contract deployed successfully . Submit the data and proceed with updating the users"
        );
        console.log("receipt", receipt);
        this.setState(
          {
            currPhaseDetails: {
              ...currPhaseDetails,
              phaseContractAddress: receipt.contractAddress,
              contractHash: receipt.transactionHash,
            },
            phaseContractAddress: receipt.contractAddress,
            showLoader: false,
          },
          () => this.getContractABI()
        ); // contains the new contract phaseContractAddress
        return true;
      });
  };
  getContractABI = async () => {
    try {
      const { currPhaseId, phases, network } = this.state;
      if (phases[currPhaseId].phaseContractAddress && network) {
        const getInstance = await getContractInstanceAbi(
          phases[currPhaseId].phaseContractAddress,
          "igo"
        );
        this.setState({ contractInstance: getInstance });
        return true;
      }
    } catch (err) {
      return false;
    }
  };
  maxmPoolRaise = async () => {
    const { currPhaseDetails } = this.state;
    if (this.state.phasePoolRaise === "") {
      return this.genrateError("Please enter some value for phasePoolRaise");
    }
    console.log("addr", currPhaseDetails.phaseContractAddress);
    this.setState({ showLoader: true });
    const curr_abi = await IGOContractAbi;
    let contract = await new web3.eth.Contract(
      curr_abi,
      currPhaseDetails.phaseContractAddress
    );
    // currPhaseDetails.phaseContractAddress,
    console.log("contractt", contract);
    await contract.methods
      .updateMaxCap(web3.utils.toWei(currPhaseDetails?.phasePoolRaise))
      .send({
        from: this.state.web3Data.accounts[0],
      })
      .on("error", (error) => {
        console.log("error", error);
        this.setState({ showLoader: false });
        return false;
      });
  };

  updateIGOTime = async (timeStamp, fxnType) => {
    this.setState({ showLoader: true });

    try {
      let hash = "";
      let currMethod =
        fxnType === "maxCap"
          ? "updateMaxCap"
          : fxnType === "st"
          ? "updateStartTime"
          : "updateEndTime";
      const contractInstance = getContractInstanceAbi(
        this.state.phases[this.state.phase].phaseContractAddress,
        "igo"
      );
      const addToContract = await contractInstance.methods[currMethod](
        timeStamp
      )
        .send({ from: this.state.web3Data.accounts[0] })
        .on("transactionHash", (_hash) => {
          hash = _hash;
        })
        .on("receipt", (receipt) => {
          this.genrateSuccess("Selected phase rewards added to blockchain. ");
          this.setState({ showLoader: false });
        });
    } catch (err) {
      this.setState({ showLoader: false });
      console.log(err);
    }
  };

  updateIGOUsers = async () => {
    try {
      const {
        igoUsersData,
        currPhaseId,
        web3Data,
        phases,
        phaseContractAddress,
        currPhaseDetails,
      } = this.state;
      const users = igoUsersData.map((user) => user.walletAddress);
      const tiers = igoUsersData.map((user) => user.tier);
      console.log(phases[currPhaseId], phaseContractAddress);
      const contractInstance = getContractInstanceAbi(
        phases[currPhaseId]
          ? phases[currPhaseId].phaseContractAddress
          : currPhaseDetails.phaseContractAddress,
        "igo"
      );

      let hash = "";
      console.log("i am called", users, tiers);
      const addToContract = await contractInstance.methods
        .updateUsers(users, tiers)
        .send({ from: web3Data.accounts[0] })
        .on("transactionHash", (_hash) => {
          hash = _hash;
        })
        .on("receipt", (receipt) => {
          this.genrateSuccess("Slot Updated.");
          this.setState({ showLoader: false });
        });
    } catch (err) {
      this.setState({ showLoader: false });
      console.log(err);
    }
  };

  updateTiers = async () => {
    try {
      const { igoUsersData, currPhaseId, web3Data, phases, currPhaseDetails } =
        this.state;
      const {
        tiers,
        maxTierCap,
        maxUserCap,
        minUserCap,
        tierUsers,
        phaseContractAddress,
      } = currPhaseDetails;
      // const users = igoUsersData.map((user) => user.walletAddress);
      // const tiers = igoUsersData.map((user) => user.tier);
      const nMaxTierCap = maxTierCap.map((ele) => web3.utils.toWei(ele));
      const nMinUserCap = minUserCap.map((ele) => web3.utils.toWei(ele));
      const nMaxUserCap = maxUserCap.map((ele) => web3.utils.toWei(ele));
      const contractInstance = getContractInstanceAbi(
        phases[currPhaseId]
          ? phases[currPhaseId].phaseContractAddress
          : phaseContractAddress,
        "igo"
      );

      let hash = "";
      console.log(nMaxTierCap, nMinUserCap, nMaxUserCap);
      // console.log("i am called", users, tiers);
      const addToContract = await contractInstance.methods
        .updateTiers(tiers, nMaxTierCap, nMinUserCap, nMaxUserCap, tierUsers)
        .send({ from: web3Data.accounts[0] })
        .on("transactionHash", (_hash) => {
          hash = _hash;
        })
        .on("receipt", (receipt) => {
          this.genrateSuccess("Slot Updated.");
          this.setState({ showLoader: false });
        });
    } catch (err) {
      this.setState({ showLoader: false });
      console.log(err);
    }
  };
  updateTokenSupply = () => {
    console.log("Update token supply", this.state.currPhaseDetails);
    this.setState({
      currPhaseDetails: {
        ...this.state.currPhaseDetails,
        igoTokenSupply: this.state.price * this.state.phasePoolRaise,
      },
    });
  };
  readXLSXFile = (f) => {
    var name = f.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      let usersWalletAddress = [];
      let userTiers = [];
      let totalTierUsers = [];
      let tiers = [];
      for (let i = 2; i <= 9; i++) {
        const wsname = wb.SheetNames[i];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        totalTierUsers.push(data.length - 1);
        tiers.push(i);
        for (let j = 1; j < data.length; j++) {
          usersWalletAddress.push(data[j][10]);
          userTiers.push(i);
        }
      }
      this.setState({
        igoUsersWalletAddress: usersWalletAddress,
        igoUsersTiers: userTiers,
        currPhaseDetails: {
          ...this.state.currPhaseDetails,
          tierUsers: totalTierUsers,
          tiers: tiers,
        },
      });
    };
    reader.readAsBinaryString(f.target.files[0]);

    // Printing data
    // console.log(data);
  };
  compactDetail = (stringValue) => {
    const newStringValue = stringValue;
    if (stringValue) {
      const res =
        stringValue.length <= 30
          ? stringValue
          : newStringValue.substring(0, 15) +
            "..." +
            newStringValue.substring(
              newStringValue.length - 15,
              newStringValue.length
            );
      return res;
    }
  };
  render() {
    // const str = sessionStorage.getItem("igoData");
    // const igoData = JSON.parse(str);
    console.log("allIgoList", this.state.allIgoList);
    const {
      isEdit,
      currPhaseDetails,
      addUpdatePhaseModal,
      currPhaseId,
      phases,
    } = this.state;
    console.log("igoUsers", currPhaseDetails);
    // let isEdit = this.state.isEdit;
    let completedPoolEdit = this.props.isCompleted;
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: 700,
      overflow: "hidden",
      overflowY: "scroll",
      width: 400,
      bgcolor: "background.paper",
      borderRadius: "10px",
      boxShadow: 24,
      p: 4,
    };

    return (
      <Page title="Add Claim Pools | Raiser Snapshot">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography
              variant="h4"
              gutterBottom
              // onClick={() => this.updateMerkleVestingHashes()}
            >
              Add new IDO
            </Typography>

            <Button
              variant="contained"
              onClick={() => {
                // this.connectMetamask();
              }}
              startIcon={
                this.state.web3Data?.isLoggedIn ? null : (
                  <Icon icon={link2Outline} />
                )
              }
              sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)" }}
            >
              {compactAddress(this.state.web3Data.accounts[0])}
            </Button>
          </Stack>

          <Card style={{ padding: "20px", backgroundColor: '#151517' }}>
            {/* <FormikProvider value={formik}> */}
            {/* <Form autoComplete='off' noValidate onSubmit={handleSubmit}> */}
            {/* <Form autoComplete='off'> */}
            {/* <form onSubmit={this.onSubmit}>
             */}
            <form onSubmit={this.newSubmit}>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <InputLabel>Select IDO name</InputLabel>
                  <Select
                    // disabled={isEdit}
                    value={this.state.igoName}
                    label="Select Vesting type"
                    onChange={(e) => {
                      this.setState({
                        igoName: e.target.value,
                      });
                    }}
                    required
                  >
                    {this.state.id === "" ? (
                      this.state.allIGOName
                        .filter(({ projectName }) => {
                          return !this.state.allIgoList.find(
                            (igoD) => igoD?.igoName === projectName
                          );
                        })
                        .map((name) => {
                          return (
                            <MenuItem value={`${name?.projectName}`} style={{backgroundColor: '#151517'}}>
                              {name?.projectName}
                            </MenuItem>
                          );
                        })
                    ) : (
                      <MenuItem value={`${this.state.igoName}`}>
                        {this.state.igoName}
                      </MenuItem>
                    )}
                    {/* <MenuItem value="monthly">Monthly Vesting</MenuItem>
                    <MenuItem value="linear">Linear Vesting</MenuItem>
                    <MenuItem value="merkle">
                      Monthly Accumulation Vesting(merkle)
                    </MenuItem> */}
                  </Select>
                </FormControl>
                {/* <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="IGO name"
                  type="text"
                  value={this.state.igoName}
                  label="IGO Name"
                  onChange={(e) => {
                    this.setState({ igoName: e.target.value });
                  }}
                  required
                /> */}
                <Editor
                  apiKey={process.env.TINY_API_KEY}
                  init={{
                    height: 300,
                    content_style: "body {color: white;}"
                  }}
                  value={this.state.descrp}
                  onInit={(evt, editor) => {
                    this.setState({
                      igoDescription: editor.getContent({ format: "text" }),
                    });
                  }}
                  onEditorChange={(e, editor) => {
                    this.setState({ descrp: e });
                    this.setState({
                      igoDescription: editor.getContent({ format: "html" }),
                    });
                  }}
                />

                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="IGO Token"
                  type="text"
                  value={this.state.igoTokenName}
                  label="IDO Token Name"
                  onChange={(e) => {
                    this.setState({ igoTokenName: e.target.value });
                  }}
                  required
                />

                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="IGO Token"
                  type="text"
                  value={this.state.igoTokenAddress}
                  label="IDO Token Address"
                  onChange={(e) => {
                    this.setState({ igoTokenAddress: e.target.value });
                  }}
                  required
                />

                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="symbol"
                  type="text"
                  value={this.state.igoTokenSymbol}
                  label="IDO Token Symbol"
                  onChange={(e) => {
                    this.setState({ igoTokenSymbol: e.target.value });
                  }}
                  required
                />
                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="decimal"
                  type="number"
                  value={this.state.igoTokenDecimal}
                  label="IDO Token Decimal"
                  onChange={(e) => {
                    this.setState({ igoTokenDecimal: e.target.value });
                  }}
                  required
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    disabled={isEdit}
                    renderInput={(props) => <TextField {...props} />}
                    label="IDO Token Distribution Date"
                    value={this.state.tokenDistributionDate}
                    onChange={(newValue) => {
                      this.setState({ tokenDistributionDate: newValue });
                    }}
                    required
                  />
                </LocalizationProvider>
                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="paymentTokenAddress"
                  type="text"
                  value={this.state.paymentTokenAddress}
                  label="Payment Token Address"
                  onChange={(e) => {
                    this.setState({ paymentTokenAddress: e.target.value });
                  }}
                  required
                />
                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="paymentTokenSymbol"
                  type="text"
                  value={this.state.paymentTokenSymbol}
                  label="Payment Token Symbol"
                  onChange={(e) => {
                    this.setState({ paymentTokenSymbol: e.target.value });
                  }}
                  required
                />

                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="up_pool_raise"
                  type="Schema.Types.Decimal128"
                  value={this.state.targetPoolRaise}
                  label="Target Pool Raise"
                  onChange={(e) => {
                    this.setState({ targetPoolRaise: e.target.value });
                  }}
                  required
                />
                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="image"
                  type="text"
                  value={this.state.imageURL}
                  label="Add image url"
                  onChange={(e) => {
                    this.setState({ imageURL: e.target.value });
                  }}
                  required
                />

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Access Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.accessType}
                    label="Network Type"
                    onChange={(e) =>
                      this.setState({ accessType: e.target.value })
                    }
                  >
                    <MenuItem value={"public"} style={{backgroundColor: '#151517'}}>Public</MenuItem>
                    <MenuItem value={"private"} style={{backgroundColor: '#151517'}}>Private</MenuItem>
                    {/* <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Network</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.network}
                    label="Network Type"
                    onChange={(e) => this.setState({ network: e.target.value })}
                  >
                    <MenuItem value={"binance"} style={{backgroundColor: '#151517'}}>Binance</MenuItem>
                    <MenuItem value={"ethereum"} style={{backgroundColor: '#151517'}}>Ethereum</MenuItem>
                    <MenuItem value={"polygon"} style={{backgroundColor: '#151517'}}>Polygon</MenuItem>
                    {/* <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Network Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.networkType}
                    label="Network Type"
                    onChange={(e) =>
                      this.setState({ networkType: e.target.value })
                    }
                  >
                    <MenuItem value={"mainnet"} style={{backgroundColor: '#151517'}}>Mainnet</MenuItem>
                    <MenuItem value={"testnet"} style={{backgroundColor: '#151517'}}>Testnet</MenuItem>
                    {/* <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>

                {/* <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="contract_type"
                  type="text"
                  value={this.state.contract_type}
                  label="Contract_type"
                  onChange={(e) => {
                    this.setState({ contract_type: e.target.value });
                  }}
                  required
                /> */}
                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="Owner Address"
                  type="text"
                  value={this.state.ownerAddress}
                  label="Owner Address"
                  onChange={(e) => {
                    this.setState({ ownerAddress: e.target.value });
                  }}
                  required
                />
                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="price"
                  type="text"
                  value={this.state.price}
                  label={`Price/${this.state.paymentTokenSymbol}`}
                  onChange={(e) => {
                    this.setState({ price: e.target.value });
                  }}
                  required
                />

                <VestingTitle>
                  Phases
                  <Icon
                    icon={PlusCircleFill}
                    width={30}
                    height={30}
                    onClick={() =>
                      this.setState({
                        addPhase: true,
                        addUpdatePhaseModal: true,
                        currVestingRootHash: "",
                      })
                    }
                  />
                </VestingTitle>
                <VestingTable>
                  <div className="table-responsive">
                    <table cellPadding={0} cellSpacing={0}>
                      <thead>
                        <th>Sr. No.</th>
                        <th>Contract Address</th>
                        <th>Max Tier Alloc.</th>
                        <th>Min Tier Alloc.</th>
                        <th>Start Time</th>
                        <th>Actions</th>
                        <th>Status</th>
                      </thead>
                      <tbody>
                        {this.state.phases.map((phase, key) => (
                          <tr
                          // className={
                          //   selectedVestingId.includes(key) ? "active" : null
                          // }
                          // onClick={() => {
                          //   this.setState({ currVestingPoolId: key });
                          //   this.addVestingToUpload(key);
                          // }}
                          >
                            <td>{key + 1}</td>
                            <td>
                              {compactAddress(phase.phaseContractAddress)}
                            </td>
                            <td>{phase.maxUserAllocation}</td>
                            <td>{phase.minUserAllocation}</td>
                            <td>
                              {" "}
                              {TimeStampToDateString(phase.start_date)}
                              {console.log("phaseee", phase)}
                            </td>
                            <td>
                              <IconList>
                                <Icon
                                  className="green"
                                  icon={EditOutline}
                                  width={25}
                                  height={25}
                                  onClick={() =>
                                    this.setState({
                                      addUpdatePhaseModal: true,
                                      // currPhaseId: key,
                                      currPhaseId: phase._id,
                                      currPhaseDetails: phase,
                                    })
                                  }
                                />
                                {/* <Icon
                                  className="red"
                                  icon={CloseCircleFill}
                                  width={25}
                                  height={25}
                                  onClick={() =>
                                    this.updateVestings(key + 1, true)
                                  }
                                /> */}
                              </IconList>
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  phase.poolStatus === "uploaded" ? true : false
                                }
                                onClick={() => {
                                  this.setState({ currVestingPoolId: key });
                                  this.addVestingToUpload(key);
                                }}
                                onChecked={() => {
                                  this.setState({ currVestingPoolId: key });
                                  this.addVestingToUpload(key);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </VestingTable>
                <VestingTitle>
                  Social Links
                  <Icon
                    icon={PlusCircleFill}
                    width={30}
                    height={30}
                    onClick={() =>
                      this.setState({
                        addSocialModal: true,
                        // currVestingRootHash: "",
                      })
                    }
                  />
                </VestingTitle>
                {(this.state.twitter != "" ||
                  this.state.telegram != "" ||
                  this.state.facebook != "") && (
                  <VestingTable>
                    <div className="table-responsive">
                      <table cellPadding={0} cellSpacing={0}>
                        <thead>
                          <th>Twitter</th>
                          <th>Telegram</th>
                          <th>Git</th>
                          <th>Reddit</th>
                          <th>Medium</th>
                          <th>BrowserWeb</th>
                          <th>YouTube</th>
                          <th>Instagaram</th>
                          <th>whitePaper</th>
                          <th>Facebook</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{compactAddress(this.state.twitter)}</td>
                            <td>{compactAddress(this.state.telegram)}</td>
                            <td>{compactAddress(this.state.git)}</td>
                            <td>{compactAddress(this.state.reddit)}</td>
                            <td>{compactAddress(this.state.medium)}</td>
                            <td>{compactAddress(this.state.browser_web)}</td>
                            <td>{compactAddress(this.state.youtube)}</td>
                            <td>{compactAddress(this.state.instagram)}</td>
                            <td>{compactAddress(this.state.white_paper)}</td>
                            <td>{compactAddress(this.state.facebook)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </VestingTable>
                )}

                <Modal
                  open={addUpdatePhaseModal}
                  onClose={() => this.setState({ addUpdatePhaseModal: false })}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{ ...style, width: 800, backgroundColor: '#151517' }}>
                    <h3 style={{ marginBottom: "25px" }}>Add Phase one</h3>
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={currPhaseDetails.phaseName}
                      type="text"
                      label="Add phaseName"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          currPhaseDetails: {
                            ...currPhaseDetails,
                            phaseName: e.target.value,
                          },
                        })
                      }
                    />

                    {/* <TextField
                      disabled={isEdit}
                      fullWidth
                      value={currPhaseDetails?.phaseContractAddress}
                      type="text"
                      label="Add phaseContractAddress"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          currPhaseDetails: {
                            ...currPhaseDetails,
                            phaseContractAddress: e.target.value,
                          },
                        })
                      }
                    /> */}
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={currPhaseDetails.minUserAllocation}
                      type="text"
                      label="Add Min User Allocation"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          currPhaseDetails: {
                            ...currPhaseDetails,
                            minUserAllocation: e.target.value,
                          },
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={currPhaseDetails.maxUserAllocation}
                      type="text"
                      label="Add Max User Allocation"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          currPhaseDetails: {
                            ...currPhaseDetails,
                            maxUserAllocation: e.target.value,
                          },
                        })
                      }
                    />
                    {/* <ParentField>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DateTimePicker
                            label="Date&Time picker"
                            value={dayjs("2014-08-18T21:11:54")}
                            onChange={(newValue) => {
                              var date = newValue;
                              var now_utc = Date.UTC(
                                date.getFullYear(),
                                date.getMonth(),
                                date.getDate(),
                                date.getHours(),
                                date.getMinutes(),
                                date.getSeconds()
                              );
                              this.setState({
                                currPhaseDetails: {
                                  ...currPhaseDetails,
                                  start_date: newValue,
                                },
                              });
                            }}
                            required
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </ParentField> */}

                    {/* <TextField
                      disabled={isEdit}
                      fullWidth
                      value={currPhaseDetails.start_date}
                      type="text"
                      label="start_date"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          currPhaseDetails: {
                            ...currPhaseDetails,
                            start_date: e.target.value,
                          },
                        })
                      }
                    /> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        disabled={isEdit}
                        renderInput={(props) => (
                          <TextField
                            style={{ marginBottom: "25px", display: "flex" }}
                            {...props}
                          />
                        )}
                        label="Select Start Date (UTC)"
                        // value={currPhaseDetails.start_date}
                        value={
                          this.state.id === "" && this.state.addPhase
                            ? this.state.start_date
                            : this.state.id !== "" && this.state.addPhase
                            ? this.state.start_date
                            : TimeStampToDateString(currPhaseDetails.start_date)
                        }
                        onChange={(newValue) => {
                          this.setState({
                            currPhaseDetails: {
                              ...currPhaseDetails,
                              start_date: dayjs(newValue).unix(),
                            },
                            start_date: newValue,
                          });
                        }}
                        required
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        disabled={isEdit}
                        renderInput={(props) => (
                          <TextField
                            style={{ marginBottom: "25px", display: "flex" }}
                            {...props}
                          />
                        )}
                        label="Select End Date (UTC)"
                        // value={currPhaseDetails.end_date}
                        value={
                          this.state.id === "" && this.state.addPhase
                            ? this.state.end_date
                            : this.state.id !== "" && this.state.addPhase
                            ? this.state.end_date
                            : TimeStampToDateString(currPhaseDetails.end_date)
                        }
                        onChange={(newValue) => {
                          this.setState({
                            currPhaseDetails: {
                              ...currPhaseDetails,
                              end_date: dayjs(newValue).unix(),
                            },
                            end_date: newValue,
                          });
                        }}
                        required
                      />
                    </LocalizationProvider>

                    {/* <TextField
                      disabled={isEdit}
                      fullWidth
                      value={currPhaseDetails.end_date}
                      type="text"
                      label="End Date"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          currPhaseDetails: {
                            ...currPhaseDetails,
                            end_date: e.target.value,
                          },
                        })
                      }
                    /> */}

                    {/* <TextField
                      disabled={isEdit}
                      fullWidth
                      value={
                        currPhaseDetails.phasePoolRaise
                          ? currPhaseDetails.phasePoolRaise * this.state.price
                          : 0
                      }
                      // value={
                      //   currPhaseDetails.igoTokenSupply
                      //     ? currPhaseDetails.igoTokenSupply
                      //     : 0
                      // }
                      type="text"
                      label="Add IGO Token Supply"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          currPhaseDetails: {
                            ...currPhaseDetails,
                            igoTokenSupply: e.target.value,
                          },
                        })
                      }
                    /> */}
                    <TextField
                      disabled
                      fullWidth
                      value={this.state.price * this.state.phasePoolRaise}
                      type="number"
                      label={`${this.state.igoTokenSymbol} Token Supply`}
                      style={{ marginBottom: "25px" }}
                    />
                    <hr />
                    <hr />
                    <br />
                    <Divider />
                    {/* <ParentField> */}
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      autoComplete="file"
                      type="file"
                      id="claimFile"
                      accept=".csv"
                      onChange={async (e) => {
                        this.setState({ csvFile: e.target.files[0] });
                        const list = await this.readXLSXFile(e);
                        this.setState({
                          xlsxIgoUsersData: list,
                        });
                      }}
                      placeholder={"Upload IGO File in format of csv"}
                      required
                    />
                    {/* </ParentField> */}

                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={currPhaseDetails.tiers}
                      type="text"
                      label="Tiers"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          currPhaseDetails: {
                            ...currPhaseDetails,
                            tiers: this.splitTierData(e.target.value),
                          },
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={currPhaseDetails.maxTierCap}
                      type="text"
                      label="Maximum tier cap"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          currPhaseDetails: {
                            ...currPhaseDetails,
                            maxTierCap: this.splitTierData(e.target.value),
                          },
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={currPhaseDetails.minUserCap}
                      type="text"
                      label="Minimum user cap"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          currPhaseDetails: {
                            ...currPhaseDetails,
                            minUserCap: this.splitTierData(e.target.value),
                          },
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={currPhaseDetails.maxUserCap}
                      type="text"
                      label="Maximum user cap"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          currPhaseDetails: {
                            ...currPhaseDetails,
                            maxUserCap: this.splitTierData(e.target.value),
                          },
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={currPhaseDetails.tierUsers}
                      type="text"
                      style={{ marginBottom: "25px" }}
                      label="Tier Users"
                      onChange={(e) =>
                        this.setState({
                          currPhaseDetails: {
                            ...currPhaseDetails,
                            tierUsers: this.splitTierData(e.target.value),
                          },
                        })
                      }
                    />
                    <ParentField>
                      <TextField
                        disabled={isEdit}
                        fullWidth
                        value={currPhaseDetails.phasePoolRaise}
                        type="text"
                        label="Add Phase Pool Raise"
                        // style={{ marginBottom: "25px" }}
                        onChange={(e) =>
                          this.setState({
                            currPhaseDetails: {
                              ...currPhaseDetails,
                              phasePoolRaise: e.target.value,
                              igoTokenSupply: this.state.price * e.target.value,
                            },
                            phasePoolRaise: e.target.value,
                          })
                        }
                      />
                      {currPhaseDetails?.phaseContractAddress && (
                        <LoadingButton
                          disabled={!currPhaseDetails?.phasePoolRaise}
                          type="button"
                          size="small"
                          variant="contained"
                          loading={
                            !currPhaseDetails?.phasePoolRaise &&
                            this.state.showLoader
                          }
                          onClick={() => this.maxmPoolRaise()}
                          style={{ color: "#444444" }}
                        >
                          MaxmPoolRaise
                        </LoadingButton>
                      )}
                    </ParentField>
                    <br />
                    <ParentField>
                      <TextField
                        // disabled={isEdit}
                        fullWidth
                        autoComplete="Contract Address"
                        type="text"
                        value={currPhaseDetails?.phaseContractAddress}
                        label="Contract Address"
                        disabled={this.state.network === "" || isEdit}
                        onChange={(e) => {
                          this.setState(
                            {
                              currPhaseDetails: {
                                ...currPhaseDetails,
                                phaseContractAddress: e.target.value,
                              },
                            },
                            () => {
                              this.getContractABI();
                            }
                          );
                        }}
                        required
                      />
                      <LoadingButton
                        disabled={currPhaseDetails?.phaseContractAddress}
                        type="button"
                        size="small"
                        variant="contained"
                        loading={
                          !currPhaseDetails?.phaseContractAddress &&
                          this.state.showLoader
                        }
                        onClick={() => this.deployContract()}
                        style={{ color: "#444444" }}
                      >
                        Deploy IGO Contract
                      </LoadingButton>
                    </ParentField>
                    <br />
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      loading={
                        this.state.showLoader &&
                        this.state.contractAddressOfClaim
                      }
                      style={{ marginTop: "25px", color: "#000000" }}
                      onClick={() => this.updateTiers()}
                      sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)" }}
                    >
                      Update Tier Details
                    </LoadingButton>
                    <Divider />
                    <br />
                    <hr />
                    <hr style={{ marginBottom: "25px" }} />

                    <ParentField>
                      <TextField
                        disabled={isEdit}
                        fullWidth
                        autoComplete="file"
                        type="file"
                        id="claimFile"
                        accept=".csv"
                        onChange={async (e) => {
                          this.setState({ csvFile: e.target.files[0] });
                          const list = await readFile(e);
                          this.setState({
                            igoUsersData: list,
                            // totalAmount: this.getTotalAmount(list),
                          });
                        }}
                        placeholder={"Upload IGO File in format of csv"}
                        // label='Upload Claim File in format of csv'
                        required
                      />
                      {/* <TextField
                    // disabled={isEdit}
                    fullWidth
                    autoComplete="Contract Address"
                    type="text"
                    value={this.state.contractAddressOfClaim}
                    label="Contract Address"
                    disabled={this.state.network === "" || isEdit}
                    onChange={(e) => {
                      this.setState(
                        { contractAddressOfClaim: e.target.value },
                        () => {
                          this.getContractABI();
                        }
                      );
                    }}
                    required
                  /> */}
                      {/* {this.state.vestingType === "linear" ||
                      this.state.vestingType === "merkle" ? ( */}
                      <LoadingButton
                        disabled={this.state.contractAddressOfClaim}
                        type="button"
                        size="small"
                        variant="contained"
                        // loading={
                        //   !this.state.contractAddressOfClaim &&
                        //   this.state.showLoader
                        // }
                        onClick={() => this.updateIGOUsers()}
                        style={{ color: "#000000" }}
                      >
                        Update Users
                      </LoadingButton>
                      {/* ) : null} */}
                    </ParentField>

                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        // this.updateVestings();
                        let newPhases = phases;
                        if (currPhaseId) {
                          newPhases[currPhaseId] = currPhaseDetails;
                        } else {
                          newPhases.push(currPhaseDetails);
                        }

                        this.setState({
                          addUpdatePhaseModal: false,
                          phases: newPhases,
                        });
                      }}
                      sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)" }}
                      style={{ color: "#000000" }}
                    >
                      Add
                      {/* ) : null} */}
                    </LoadingButton>
                  </Box>
                </Modal>

                <Modal
                  open={this.state.addSocialModal}
                  onClose={() => this.setState({ addSocialModal: false })}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{ ...style, width: 800, backgroundColor: '#151517' }}>
                    <h3 style={{ marginBottom: "25px" }}>
                      {/* {`${this.state.idoName} (${ordinal_suffix_of(
                        this.state.vestingsArr.length + 1
                      )} Vesting)`} */}
                      Add Url
                    </h3>
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={this.state.twitter}
                      type="text"
                      label="Add twitterUrl"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          twitter: e.target.value,
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={this.state.telegram}
                      type="text"
                      label="Add telegramUrl"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          telegram: e.target.value,
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={this.state.git}
                      type="text"
                      label="Add gitUrl"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          git: e.target.value,
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={this.state.reddit}
                      type="text"
                      label="Add redditUrl"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          reddit: e.target.value,
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={this.state.medium}
                      type="text"
                      label="Add mediumUrl"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          medium: e.target.value,
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={this.state.browser_web}
                      type="text"
                      label="Add browserWebUrl"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          browser_web: e.target.value,
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={this.state.youtube}
                      type="text"
                      label="Add youTubeUrl"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          youtube: e.target.value,
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={this.state.instagram}
                      type="text"
                      label="Add instagramUrl"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          instagram: e.target.value,
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={this.state.discord}
                      type="text"
                      label="Add discordUrl"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          discord: e.target.value,
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={this.state.white_paper}
                      type="text"
                      label="Add whitePaperUrl"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          white_paper: e.target.value,
                        })
                      }
                    />
                    <TextField
                      disabled={isEdit}
                      fullWidth
                      value={this.state.facebook}
                      type="text"
                      label="Add facebookUrl"
                      style={{ marginBottom: "25px" }}
                      onChange={(e) =>
                        this.setState({
                          facebook: e.target.value,
                        })
                      }
                    />

                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        // this.updateVestings();
                        this.setState({ addSocialModal: false });
                      }}
                      sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)" }}
                      style={{ color: "#000000" }}
                    >
                      Add
                      {/* ) : null} */}
                    </LoadingButton>
                  </Box>
                </Modal>
              </Stack>
              <br />

              <LoadingButton
                fullWidth
                // disabled={!this.state.contractAddressOfClaim}
                size="large"
                type="submit"
                variant="contained"
                loading={
                  this.state.showLoader && this.state.contractAddressOfClaim
                }
                style={{ marginTop: "25px", color: "#000000" }}
                sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)" }}
              >
                Submit
              </LoadingButton>

              <LoadingButton
                fullWidth
                // disabled={!this.state.contractAddressOfClaim}
                size="small"
                type="button"
                variant="contained"
                loading={
                  this.state.showLoader && this.state.contractAddressOfClaim
                }
                style={{ marginTop: "25px", color: "#000000" }}
                onClick={() => this.pauseUnpauseIgo()}
                sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)" }}
              >
                Pause
              </LoadingButton>
              {/* //////////////////////////////////////// */}
              {/* {this.state.vestingType === "merkle" && (
                <LoadingButton
                  fullWidth
                  disabled={!this.state.contractAddressOfClaim}
                  size="large"
                  type="button"
                  variant="contained"
                  onClick={() => this.addMultiplePhaseRewards()}
                  loading={
                    this.state.showLoader && this.state.contractAddressOfClaim
                  }
                  style={{ marginTop: "25px" }}
                >
                  Add Vesting Tokens
                </LoadingButton>
              )} */}
            </form>
          </Card>
        </Container>
      </Page>
    );
  }
}
export default withRouter(Claim);

const ParentField = styled.div`
  position: relative;
  button {
    position: absolute;
    right: 10px;
    top: calc(50% - 20px);
    color: #fff;
    background: linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%);
    padding: 10px 22px;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    font-family: Public Sans, sans-serif;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
`;
const CustomSwitch = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #FBAE48;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #FBAE48;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
const VestingTitle = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
    cursor: pointer;
    color: #5bb3fc;
    box-shadow: 0px 0px 5px 0px #70bdfc;
    border-radius: 50%;
    :hover {
      box-shadow: 0px 0px 5px 1px #70bdfc;
      transition: 0.5s ease all;
    }
  }
`;

const ItemRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ItemList = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #dbeefe;
  border-radius: 30px;
  margin: 0px 15px 15px 0px;
  width: calc(33.33% - 15px);
  background-color: #f6f9fb;
  &.active {
    border: 1px solid #5bb3fc;
  }
  &.completed {
    border: 1px solid #4caf50;
    background-color: #ddf9de;
  }
  :hover {
    border: 1px solid #b9d9f5;
  }
  p {
    border-right: 1px solid #f6f9fb;
    padding: 10px;
    width: 40px;
    text-align: center;
    background: linear-gradient(90deg, #5bb3fc, #d9edfe);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    &.completed {
      background: linear-gradient(90deg, #70c274, #ddf9de);
    }
  }
  :last-child {
    margin-right: 0px;
  }
  .right-part {
    position: relative;
    width: -webkit-fill-available;
    font-size: 14px;
    .icon-list {
      position: absolute;
      right: 5px;
      top: calc(50% - 15px);
      svg {
        margin: 0px 2px;
        cursor: pointer;
        color: #ff4842;
        box-shadow: 0px 0px 2px 0px #ff4842;
        border-radius: 50%;
        :hover {
          box-shadow: 0px 0px 5px 1px #70bdfc;
          transition: 0.5s ease all;
        }
        &.green {
          color: #4caf50;
          box-shadow: 0px 0px 2px 0px #4caf50;
        }
      }
    }
  }
`;

const DateText = styled.div`
  padding: 10px;
`;

const VestingPer = styled.div`
  background-color: #d9edfe;
  padding: 12px 10px;
  font-size: 14px;
  &.completed {
    background-color: #c3f1c5;
  }
`;

const VestingTable = styled.div`
  .table-responsive {
    overflow-x: auto;
  }
  margin-bottom: 20px !important;
  table {
    width: 100%;
    border-bottom: 1px solid #dce0e4;
    td {
      border: 1px solid #dce0e4;
      text-align: center;
      border-right: 0px;
      border-bottom: 0px;
      padding: 5px 10px;
      white-space: nowrap;
      :last-child {
        border-right: 1px solid #dce0e4;
      }
      svg {
        cursor: pointer;
        margin: 5px;
        &.green {
          color: #2196f3;
        }
        &.red {
          color: #e10404;
        }
      }
    }
  }
`;

const IconList = styled.div`
  display: flex;
  align-items: Center;
  justify-content: center;
`;
