import * as React from "react";
import { CSVLink } from "react-csv";
import { web3 } from "../web3";
import journeyAbi from "./journy.json";
// material
import {
  Card,
  Button,
  Stack,
  TextField,
  Container,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import * as XLSX from "xlsx";
import { Icon } from "@iconify/react";
import ArrowBackOutline from "@iconify/icons-eva/arrow-back-outline";
import CloudDownloadOutline from "@iconify/icons-eva/cloud-download-outline";

class GenerateCSV extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      invalidAddress: [],
      igoname: "",
      isLoading: false,
      totalAmount: 0,
      vesting_percentage: 0,
      invtFile: null,
      invtData: [],
      csvData: [],
      dataArr: [],
      fromIt: 1,
      toIt: 1000,
      fileError: false,
      headers: [
        { label: "walletAddress", key: "walletAddress" },
        { label: "eTokens", key: "eTokens" },
      ],
    };
  }

  // read file
  readFile = (e) => {
    console.log("called");
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      this.processData(data);
    };
    console.log("========>>>>>>>>>>>>>>>>>>>>>",reader);
    reader.readAsBinaryString(file);
  };

  // process data
  processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    console.log("sddshfvhgsfhagshfhuasb=====>>",list);
    this.setState({ invtData: list });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let csvData = [];
    let invalidAddress = [];

    let arr = this.state.invtData;
    // console.log("arrrr value =======>>>>>",arr);
    var holder = {};
    arr.forEach(function (d, id) {
      if (web3.utils.isAddress(d.From)) {
        console.log(d.From);
        if (holder.hasOwnProperty(d.From)) {
          holder[d.From] = Number(holder[d.From]) + Number(d.TokenValue);
        } else {
          holder[d.From] = Number(d.TokenValue);
        }
      } else {
        console.log(d, id);
        invalidAddress.push({ walletAddress: d.From, eTokens: d.TokenValue });
      }
    });
    console.log(holder);
    var invtData = []; // apply rewards tokens
    for (var prop in holder) {
      invtData.push({
        walletAddress: prop,
        eTokens: Number(holder[prop]) * Number(this.state.totalAmount),
      });
      console.log("invtdata======>>",invtData);
    }

    invtData.map((obj) => {
      // apply vesting percentage
      if (obj.walletAddress && obj.eTokens) {
        csvData.push({
          walletAddress: obj.walletAddress,
          eTokens:
            Number(obj.eTokens) * Number(this.state.vesting_percentage / 100),
        });
      }
      console.log("datamapof =====>>>>",invtData);
    });
    csvData.length > 0
      ? this.setState({ csvData: csvData, invalidAddress: invalidAddress })
      : this.setState({ isLoading: false, fileError: true });
  };

  getNFTjourneyDetails = async () => {
    ////////////////////////////////////////////////////////////////////////
    function range(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    }
    //////////////////////////////////////////////////////////////////////
    try {
      const contractInstance = new web3.eth.Contract(
        journeyAbi,
        "0x0b4B2bA334f476C8F41bFe52A428D6891755554d"
      );
      const arr = range(this.state.fromIt, this.state.toIt);
      const dataArr = await Promise.all(
        arr.map(async (key) => {
          return await contractInstance.methods.ownerOf(+key).call();
        })
      );
      console.log("dddddddddd+++++++==========>>>>>",
        "newData",
        dataArr,
        "oldDate",
        this.state.dataArr,
        this.state.fromIt,
        this.state.toIt
      );
      this.setState({
        dataArr: [...this.state.dataArr, ...dataArr],
      });
      this.setState({
        fromIt: this.state.fromIt + 1000,
        toIt: this.state.toIt + 1000,
      });
    } catch (err) {
      console.log(err);
    }
  };

  makeCSV = () => {
    const csvData = [];
    this.state.dataArr.map((address, key) => {
      // apply vesting percentage
      csvData.push({
        walletAddress: address,
        eTokens: key + 1,
      });
      console.log("csvdata======>>>>",csvData);
    });
    // csvData.length > 0
    //   ? this.setState({ csvData: csvData })
    //   : this.setState({ isLoading: false, fileError: true });
  };

  render() {
    console.log(this.state.invalidAddress);
    return (
      <Page title="Generate CSV File | Raiser Snapshot">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography
              variant="h4"
              gutterBottom
              onClick={() => this.getNFTjourneyDetails()}
            >
              Generate CSV (EVM chains)
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              onClick={() => this.makeCSV()}
            >
              MakeCSV
            </Typography>
            {this.state.isLoading && this.state.csvData && (
              <Button
                variant="contained"
                onClick={() => window.location.reload()}
              >
                <Icon icon={ArrowBackOutline} style={{ fontSize: "20px" }} />{" "}
                &nbsp;Back
              </Button>
            )}
          </Stack>

          <Card style={{ padding: "20px", backgroundColor: '#151517' }}>
            <form onSubmit={this.onSubmit}>
              <Stack spacing={3}>
                <TextField
                  disabled={this.state.isLoading}
                  fullWidth
                  autoComplete="IGO name"
                  type="text"
                  label="IDO name"
                  onChange={(e) => {
                    this.setState({ igoname: e.target.value });
                  }}
                  required
                />

                <TextField
                  disabled={this.state.isLoading}
                  fullWidth
                  type={"float"}
                  autoComplete="rewards"
                  inputProps={{ min: 1 }}
                  label="Total Rewards"
                  value={this.state.totalAmount}
                  onChange={(e) => {
                    this.setState({ totalAmount: e.target.value });
                  }}
                  required
                />

                <TextField
                  disabled={this.state.isLoading}
                  fullWidth
                  type={"float"}
                  autoComplete="vesting percentage"
                  inputProps={{ min: 1 }}
                  label="Vesting Percentage"
                  value={this.state.vesting_percentage}
                  onChange={(e) => {
                    this.setState({ vesting_percentage: e.target.value });
                  }}
                  required
                />

                <TextField
                  disabled={this.state.isLoading}
                  fullWidth
                  error={this.state.fileError}
                  autoComplete="file"
                  type="file"
                  id="investmentFile"
                  accept=".csv"
                  onChange={(e) => {
                    this.setState({ invtFile: e.target.files[0] });
                    this.readFile(e);
                  }}
                  placeholder={"Upload Investment File in format of csv"}
                  required
                />
              </Stack>

              {!this.state.isLoading && (
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={this.state.isLoading}
                  style={{ marginTop: "25px",  color: "#000000" }}
                  sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)" }}
                >
                  Generate
                </LoadingButton>
              )}

              {this.state.csvData && (
                <>
                  <CSVLink
                    filename={`${this.state.igoname}.csv`}
                    data={this.state.csvData}
                    headers={this.state.headers}
                    target="_blank"
                    style={{ marginTop: "25px", background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent" }}
                    className="css-1iewgp6-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root"
                  >
                    <Icon
                      icon={CloudDownloadOutline}
                      style={{ fontSize: "20px" }}
                    />{" "}
                    &nbsp;Download CSV
                  </CSVLink>
                  {this.state.invalidAddress.length ? (
                    <CSVLink
                      filename={`${this.state.igoname}InvalidAddresses.csv`}
                      data={this.state.invalidAddress}
                      headers={this.state.headers}
                      target="_blank"
                      style={{ marginTop: "25px" }}
                      className="css-1iewgp6-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root"
                    >
                      <Icon
                        icon={CloudDownloadOutline}
                        style={{ fontSize: "20px" }}
                      />{" "}
                      &nbsp;Download Invalid Addresses File
                    </CSVLink>
                  ) : null}
                </>
              )}
            </form>
          </Card>
        </Container>
      </Page>
    );
  }
}
export default GenerateCSV;
