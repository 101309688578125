import PropTypes from "prop-types";
import LogoImg from "../assets/Raiser-Final-logo 1 (1).png";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return <img src={LogoImg} alt="Raiser Fund Snapshot" />;
}
