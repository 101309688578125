import * as React from "react";
import { useState, useEffect } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import link2Outline from "@iconify/icons-eva/link-2-outline";
import { Icon } from "@iconify/react";
import styled from "styled-components";

// material
import { web3Services } from "../services/web3.service";
import { toaster } from "../utils/toast";
import {
  Card,
  Stack,
  TextField,
  Container,
  Typography,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import { services } from "../services/index";

import "react-datetime/css/react-datetime.css";
import { compactAddress, utcToLocalTimeStamp } from "src/contractData/function";
import { web3 } from "src/web3";

class AddSolanaPools extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contractAddressOfClaim: "",
      tokenAddress: "",
      vestingInfo: "",
      idoName: "",
      totalAmount: 0,
      networkId: "",
      networkSymbol: "SOL",
      networkName: "solana",
      showLoader: false,
      startDate: "",
      utcStartDate: Math.round(new Date().getTime() / 1000),
      endDate: "",
      utcEndDate: Math.round(new Date().getTime() / 1000),
      phaseNo: 0,
      logo: "",
      jsonFile: null,
      isEdit: false,
      editId: "",
      vestingType: "linear",
      vestTotalArr: [],
      isSFNT: false,
      inputProps: {
        placeholder: "Select Start date in UTC ",
        required: true,
      },

      web3Data: {
        isLoggedIn: false,
        accounts: [],
      },
    };
  }

  async componentDidUpdate(prevProps, prevState) {}

  componentDidMount = async () => {
    const path = await window.location.pathname;
    let newPath = "";
    if (this.props.isCompleted) {
      newPath = await path.replace(
        "/dashboard/editCompletedAddSolanapools/",
        ""
      );
    } else
      newPath = await path.replace("/dashboard/editPendingAddSolanapools/", "");
    if (newPath && path.includes("edit")) this.callEditPoolDetails(newPath);
    console.log(path);
    this.connectMetamask();
    let ethereum = window.ethereum;
  };

  callEditPoolDetails = async (id) => {
    const url = `claim/${this.props.isCompleted ? "single" : "get-dump"}/${id}`;
    const sendRequest = await services.Get(url);
    if (sendRequest.status === 200) {
      this.setState({ isEdit: true, editId: id });
      const data = sendRequest.data.data;

      this.setState({
        tokenAddress: data.tokenAddress,
        contractAddressOfClaim: data.contractAddress,
        logo: data.logo,
        idoName: data.name,
        phaseNo: data.phaseNo,
        totalAmount: data.amount,
        utcStartDate: data.timestamp,
        utcEndDate: +data.endTime,
        linearStartAmount: data.startAmount,
        vestingInfo: data.vestingInfo,
        isSFNT: data.isSnft,
      });
    } else {
      console.log("err");
    }
  };

  // connect metamask
  connectMetamask = async () => {
    try {
      const getAcountDetails = await web3Services.enableMetamask();
      const chainId = await window.ethereum.request({ method: "eth_chainId" });
      this.setState({
        web3Data: getAcountDetails,
        networkId: chainId,
      });
    } catch (err) {
      console.log("error is:", err);
    }
  };

  // change network

  updateCompletedPooldetails = async () => {
    this.setState({ showLoader: true });
    try {
      let data = {
        claimId: this.state.editId,
        logo: this.state.logo,
        name: this.state.idoName,
      };
      const url = `/claim/edit`;
      const sendRequest = await services.Post(url, data);

      if (sendRequest.status === 200) {
        this.setState({ showLoader: false });
        this.genrateSuccess("Pool data updated.");
        return sendRequest.data;
      }
    } catch (err) {
      this.setState({ showLoader: false });
      this.genrateError(
        `${err.message ? err.message : "Something went wrong "}`
      );
    }
  };
  genrateError = (text) => {
    return toaster(`${text}`, "error");
  };

  genrateSuccess = (text) => {
    return toaster(`${text}`, "success");
  };
  addRecords = async (data) => {
    try {
      const url = `/claim/add-dump`;
      const headers = {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      };

      const sendRequest = await services.Post(url, data);

      if (sendRequest.status === 200) {
        this.setState({ showLoader: false });
        if (!sendRequest.data.data) {
          this.genrateError(
            `${
              sendRequest.data?.message
                ? sendRequest.data.message
                : "Something went wrong "
            }`
          );
          return false;
        }
        console.log("2", sendRequest.data);
        this.genrateSuccess(
          "Users data is saved . Please proceed with transactions"
        );
        return sendRequest.data;
      }
    } catch (err) {
      this.setState({ showLoader: false });
      this.genrateError(
        `${err.message ? err.message : "Something went wrong "}`
      );
    }
  };
  newSubmit = async (ev) => {
    ev.preventDefault();
    this.setState({ showLoader: true });
    if (this.props.isCompleted) return this.updateCompletedPooldetails();
    else {
      var data = new FormData(ev.target);
      data.append("csv", this.state.jsonFile);
      data.append("contractAddress", this.state.contractAddressOfClaim);
      data.append("tokenAddress", this.state.tokenAddress);
      data.append("networkSymbol", this.state.networkSymbol);
      data.append("networkName", this.state.networkName);
      data.append("networkId", this.state.networkId);
      data.append("amount", this.state.totalAmount);
      data.append("name", this.state.idoName);
      data.append("phaseNo", this.state.phaseNo);
      data.append("vestingInfo", this.state.vestingInfo);
      data.append("logo", this.state.logo);
      data.append("timestamp", this.state.utcStartDate);
      data.append("vestingType", this.state.vestingType);
      data.append("endTime", this.state.utcEndDate);
      data.append("isSnft", this.state.isSFNT);
      await this.addRecords(data);
    }
  };
  render() {
    let isEdit = this.state.isEdit;
    let completedPoolEdit = this.props.isCompleted;
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      borderRadius: "30px",
      boxShadow: 24,
      p: 4,
    };
    return (
      <Page title="Add Solana Pools | Raiser Snapshot">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Claim Token
            </Typography>

            <Button
              variant="contained"
              onClick={() => {
                // this.connectMetamask();
              }}
              startIcon={
                this.state.web3Data.isLoggedIn ? null : (
                  <Icon icon={link2Outline} />
                )
              }
            >
              {compactAddress(this.state.web3Data.accounts[0])}
            </Button>
          </Stack>

          <Card style={{ padding: "20px" }}>
            <form onSubmit={this.newSubmit}>
              <Stack spacing={3}>
                <TextField
                  disabled={true}
                  fullWidth
                  autoComplete="Select Network"
                  type="text"
                  value="Solana"
                  label="Select Network"
                  readOnly
                />
                <TextField
                  disabled={true}
                  fullWidth
                  autoComplete="Select Vesting Type"
                  type="text"
                  value="Linear Vesting"
                  label="Select Vesting type"
                  readOnly
                />
                <TextField
                  fullWidth
                  autoComplete="Program Id"
                  type="text"
                  value={this.state.contractAddressOfClaim}
                  label="Program Id"
                  disabled={isEdit}
                  onChange={(e) => {
                    this.setState({ contractAddressOfClaim: e.target.value });
                  }}
                  required
                />
                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="Vesting Info Address"
                  type="text"
                  value={this.state.vestingInfo}
                  label="Vesting Info Address"
                  onChange={(e) => {
                    this.setState({ vestingInfo: e.target.value });
                  }}
                  required
                />
                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="Original SPL Token"
                  type="text"
                  value={this.state.tokenAddress}
                  label="Original SPL Token"
                  onChange={(e) => {
                    this.setState({ tokenAddress: e.target.value });
                  }}
                  required
                />

                <TextField
                  disabled={isEdit && !completedPoolEdit}
                  fullWidth
                  autoComplete="ido"
                  value={this.state.idoName}
                  onChange={(e) => {
                    this.setState({ idoName: e.target.value });
                  }}
                  type="text"
                  label="Ido Name"
                  required
                />

                <TextField
                  disabled={isEdit}
                  fullWidth
                  type={"number"}
                  autoComplete="rewards"
                  label="Total Rewards"
                  value={this.state.totalAmount}
                  onChange={(e) => {
                    this.setState({ totalAmount: e.target.value });
                  }}
                  required
                />
                <TextField
                  disabled={isEdit}
                  fullWidth
                  type="file"
                  accept=".json"
                  onChange={(e) => {
                    this.setState({ jsonFile: e.target.files[0] });
                  }}
                  placeholder={"Upload Claim File in format of json"}
                  required
                />

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    disabled={isEdit}
                    renderInput={(props) => <TextField {...props} />}
                    label="Select Start Date (UTC)"
                    value={utcToLocalTimeStamp(+this.state.utcStartDate * 1000)}
                    onChange={(newValue) => {
                      var date = newValue;
                      var now_utc = Date.UTC(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        date.getHours(),
                        date.getMinutes(),
                        date.getSeconds()
                      );
                      this.setState({
                        startDate: newValue,
                        utcStartDate: now_utc / 1000,
                      });
                    }}
                    required
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    disabled={isEdit}
                    renderInput={(props) => <TextField {...props} />}
                    label="Select End Date"
                    value={utcToLocalTimeStamp(+this.state.utcEndDate * 1000)}
                    onChange={(newValue) => {
                      var date = newValue;
                      var now_utc = Date.UTC(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        date.getHours(),
                        date.getMinutes(),
                        date.getSeconds()
                      );
                      this.setState({
                        endDate: newValue,
                        utcEndDate: now_utc / 1000,
                      });
                    }}
                    required
                  />
                </LocalizationProvider>
                <TextField
                  disabled={completedPoolEdit}
                  type="number"
                  fullWidth
                  autoComplete="Phase"
                  value={this.state.phaseNo}
                  onChange={(e) => {
                    this.setState({ phaseNo: e.target.value });
                  }}
                  label="Phase No"
                  required
                />

                <TextField
                  disabled={isEdit && !completedPoolEdit}
                  fullWidth
                  autoComplete="logo"
                  type="text"
                  label="Logo Url"
                  value={this.state.logo}
                  onChange={(e) => {
                    this.setState({ logo: e.target.value });
                  }}
                  required
                />
              </Stack>
              <br />
              {this.state.vestingType !== "monthly" ? (
                <>
                  <h4>SFNT Drop</h4>
                  <CustomSwitch>
                    <label className="switch">
                      <input
                        type="checkbox"
                        value={this.state.isSFNT}
                        onChange={(e) =>
                          this.setState({ isSFNT: !this.state.isSFNT })
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </CustomSwitch>
                </>
              ) : null}
              <LoadingButton
                fullWidth
                disabled={!this.state.contractAddressOfClaim}
                size="large"
                type="submit"
                variant="contained"
                loading={
                  this.state.showLoader && this.state.contractAddressOfClaim
                }
                style={{ marginTop: "25px" }}
              >
                Submit
              </LoadingButton>
            </form>
          </Card>
        </Container>
      </Page>
    );
  }
}
export default AddSolanaPools;

const ParentField = styled.div`
  position: relative;
  button {
    position: absolute;
    right: 10px;
    top: calc(50% - 20px);
    color: #fff;
    background: linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%);
    border-radius: 10px;
    padding: 10px 22px;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    font-family: Public Sans, sans-serif;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
`;
const CustomSwitch = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #FBAE48;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #FBAE48;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
const VestingTitle = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
    cursor: pointer;
    color: #5bb3fc;
    box-shadow: 0px 0px 5px 0px #70bdfc;
    border-radius: 50%;
    :hover {
      box-shadow: 0px 0px 5px 1px #70bdfc;
      transition: 0.5s ease all;
    }
  }
`;

const ItemRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ItemList = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #dbeefe;
  border-radius: 30px;
  margin: 0px 15px 15px 0px;
  width: calc(33.33% - 15px);
  background-color: #f6f9fb;
  &.active {
    border: 1px solid #5bb3fc;
  }
  &.completed {
    border: 1px solid #4caf50;
    background-color: #ddf9de;
  }
  :hover {
    border: 1px solid #b9d9f5;
  }
  p {
    border-right: 1px solid #f6f9fb;
    padding: 10px;
    width: 40px;
    text-align: center;
    background: linear-gradient(90deg, #5bb3fc, #d9edfe);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    &.completed {
      background: linear-gradient(90deg, #70c274, #ddf9de);
    }
  }
  :last-child {
    margin-right: 0px;
  }
  .right-part {
    position: relative;
    width: -webkit-fill-available;
    font-size: 14px;
    .icon-list {
      position: absolute;
      right: 5px;
      top: calc(50% - 15px);
      svg {
        margin: 0px 2px;
        cursor: pointer;
        color: #ff4842;
        box-shadow: 0px 0px 2px 0px #ff4842;
        border-radius: 50%;
        :hover {
          box-shadow: 0px 0px 5px 1px #70bdfc;
          transition: 0.5s ease all;
        }
        &.green {
          color: #4caf50;
          box-shadow: 0px 0px 2px 0px #4caf50;
        }
      }
    }
  }
`;

const DateText = styled.div`
  padding: 10px;
`;

const VestingPer = styled.div`
  background-color: #d9edfe;
  padding: 12px 10px;
  font-size: 14px;
  &.completed {
    background-color: #c3f1c5;
  }
`;
