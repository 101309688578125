import * as React from "react";
import { CSVLink } from "react-csv";
import { web3 } from "../web3";
import journeyAbi from "./journy.json";
import styled from "styled-components";

// material
import {
  Card,
  Button,
  Stack,
  TextField,
  Container,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import * as XLSX from "xlsx";
import { Icon } from "@iconify/react";
import ArrowBackOutline from "@iconify/icons-eva/arrow-back-outline";
import CloudDownloadOutline from "@iconify/icons-eva/cloud-download-outline";
import { services } from "src/services";
import { toaster } from "src/utils/toast";

class Snftsnapshot extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      invalidAddress: [],
      igoname: "",
      snftPerUnit: "",
      isLoading: false,
      totalAmount: 0,
      vesting_percentage: 0,
      invtFile: null,
      invtData: [],
      csvData: [],
      dataArr: [],
      totalCSVData: [],
      fxnType: "add_snapshotData",
      fromIt: 1,
      toIt: 1000,
      isFetching: false,
      fileError: false,
      headers: [
        { label: "walletAddress", key: "walletAddress" },
        { label: "NFT Ids", key: "id" },
        { label: "eTokens", key: "eTokens" },
      ],
      totalCSVheaders: [
        { label: "walletAddress", key: "walletAddress" },
        { label: "eTokens", key: "eTokens" },
      ],
    };
  }

  genrateError = (text) => {
    return toaster(`${text}`, "error");
  };

  genrateSuccess = (text) => {
    return toaster(`${text}`, "success");
  };
  // read file
  readFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      this.processData(data);
    };
    reader.readAsBinaryString(file);
  };

  // process data
  processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    this.setState({ invtData: list });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let csvData = [];
    let invalidAddress = [];

    let arr = this.state.invtData;
    console.log(arr);
    var holder = {};
    arr.forEach(function (d, id) {
      if (web3.utils.isAddress(d.From)) {
        console.log(d.From);
        if (holder.hasOwnProperty(d.From)) {
          holder[d.From] = Number(holder[d.From]) + Number(d.Value);
        } else {
          holder[d.From] = Number(d.Value);
        }
      } else {
        console.log(d, id);
        invalidAddress.push({ walletAddress: d.From, eTokens: d.Value });
      }
    });
    console.log(holder);
    var invtData = []; // apply rewards tokens
    for (var prop in holder) {
      invtData.push({
        walletAddress: prop,
        eTokens: Number(holder[prop]) * Number(this.state.totalAmount),
      });
    }

    invtData.map((obj) => {
      // apply vesting percentage
      if (obj.walletAddress && obj.eTokens) {
        csvData.push({
          walletAddress: obj.walletAddress,
          eTokens:
            Number(obj.eTokens) * Number(this.state.vesting_percentage / 100),
        });
      }
    });
    csvData.length > 0
      ? this.setState({ csvData: csvData, invalidAddress: invalidAddress })
      : this.setState({ isLoading: false, fileError: true });
  };

  getNFTjourneyDetails = async () => {
    this.setState({ isFetching: true });

    ////////////////////////////////////////////////////////////////////////
    function range(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    }
    //////////////////////////////////////////////////////////////////////
    try {
      const contractInstance = new web3.eth.Contract(
        journeyAbi,
        "0x0b4B2bA334f476C8F41bFe52A428D6891755554d"
      );
      const arr = range(this.state.fromIt, this.state.toIt);
      const dataArr = await Promise.all(
        arr.map(async (key) => {
          return await contractInstance.methods.ownerOf(+key).call();
        })
      );
      console.log(
        "newData",
        dataArr,
        "oldDate",
        this.state.dataArr,
        this.state.fromIt,
        this.state.toIt
      );
      this.setState({
        dataArr: [...this.state.dataArr, ...dataArr],
      });
      this.setState({
        fromIt: this.state.fromIt + 1000,
        toIt: this.state.toIt + 1000,
      });
    } catch (err) {
      console.log(err);
    }
    this.setState({ isFetching: false });
  };

  makeCSV = (data) => {
    const csvData = [];
    data.map((address, key) => {
      if (csvData.find((ele) => ele.walletAddress === address)) {
        const index = csvData.findIndex((ele) => ele.walletAddress === address);
        csvData[index] = {
          walletAddress: address,
          id: csvData[index].id + ";" + (key + 1).toString(),
          eTokens: csvData[index].eTokens + 1,
        };
        console.log(csvData[index]);
      } else {
        csvData.push({
          walletAddress: address,
          id: (key + 1).toString(),
          eTokens: 1,
        });
        console.log(csvData[csvData.length - 1]);
      }
    });
    csvData.length > 0
      ? this.setState({ csvData: csvData })
      : this.setState({ isLoading: false, fileError: true });
  };

  makeTotalCSV = (data) => {
    const csvData = [];
    data.map((ele, key) => {
      if (csvData.find((el) => el.walletAddress === ele.walletAddress)) {
        const index = csvData.findIndex(
          (el) => el.walletAddress === ele.walletAddress
        );
        csvData[index] = {
          walletAddress: ele.walletAddress,
          // id: csvData[index].id + ";" + (key + 1).toString(),
          eTokens: +csvData[index]?.eTokens + +ele.eTokens,
        };
        console.log(csvData[index]);
      } else {
        csvData.push({
          walletAddress: ele.walletAddress,
          // id: (key + 1).toString(),
          eTokens: ele.eTokens,
        });
        console.log(csvData[csvData.length - 1]);
      }
    });
    csvData.length > 0
      ? this.setState({ csvData: csvData })
      : this.setState({ isLoading: false, fileError: true });
  };
  addSnapshotData = async () => {
    var data = new FormData();
    console.log(this.state.igoname, this.state.invtFile);
    data.append("snapshotName", this.state.igoname);
    data.append("snftPerUnit", +this.state.snftPerUnit);
    data.append("csv", this.state.invtFile);

    try {
      const url = `/snft/add-users`;
      console.log(url);
      const headers = {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      };

      const sendRequest = await services.Post(url, data);
      console.log(sendRequest);
      if (sendRequest.status === 200) {
        this.setState({ showLoader: false });
        this.genrateSuccess("Snapshot data is saved");
        return sendRequest.data;
      }
    } catch (err) {
      this.setState({ showLoader: false });
      this.genrateError(
        `${err.message ? err.message : "Something went wrong "}`
      );
    }
  };

  getTotalSnapshotCSV = async () => {
    try {
      const url = `/snft/get-total`;
      console.log(url);
      const headers = {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      };

      const sendRequest = await services.Get(url);
      console.log(sendRequest);
      if (sendRequest.status === 200) {
        this.setState({ showLoader: false, totalCSVData: sendRequest.data });
        this.genrateSuccess("Snapshot data is saved");
        //  sendRequest.data;
      }
    } catch (err) {
      this.setState({ showLoader: false });
      this.genrateError(
        `${err.message ? err.message : "Something went wrong "}`
      );
    }
  };
  render() {
    console.log(this.state.invalidAddress);
    return (
      <Page title=" Snapshot functions">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Snapshot functions
            </Typography>
            <ButtonList>
              <Button
                variant="contained"
                onClick={() => this.setState({ fxnType: "add_snapshotData" })}
              >
                Add Snapshot Data
              </Button>
              {/* <Button
                variant="contained"
                onClick={() => this.setState({ fxnType: "jrnyNFTList" })}
              >
                Get Jrny NFT List
              </Button> */}
              <Button
                variant="contained"
                onClick={() => this.setState({ fxnType: "totalCSV" })}
              >
                Get total CSV List
              </Button>
            </ButtonList>

            {this.state.isLoading && this.state.csvData && (
              <Button
                variant="contained"
                onClick={() => window.location.reload()}
              >
                <Icon icon={ArrowBackOutline} style={{ fontSize: "20px" }} />{" "}
                &nbsp;Back
              </Button>
            )}
          </Stack>

          <Card style={{ padding: "20px", backgroundColor: '#151517' }}>
            {/* <form onSubmit={this.onSubmit}> */}
            <Stack spacing={3}>
              {this.state.fxnType === "add_snapshotData" ? (
                <>
                  <TextField
                    disabled={this.state.isLoading}
                    fullWidth
                    autoComplete="IGO name"
                    type="text"
                    label="Snapshot name"
                    onChange={(e) => {
                      this.setState({ igoname: e.target.value });
                    }}
                    required
                  />
                  <TextField
                    disabled={this.state.isLoading}
                    fullWidth
                    type="text"
                    label="RST Per Unit"
                    onChange={(e) => {
                      this.setState({ snftPerUnit: e.target.value });
                    }}
                    required
                  />
                </>
              ) : null}

              {this.state.fxnType === "add_snapshotData" ? (
                <TextField
                  disabled={this.state.isLoading}
                  fullWidth
                  error={this.state.fileError}
                  autoComplete="file"
                  type="file"
                  id="investmentFile"
                  accept=".csv"
                  onChange={(e) => {
                    this.setState({ invtFile: e.target.files[0] });
                    this.readFile(e);
                  }}
                  placeholder={"Upload Investment File in format of csv"}
                  required
                />
              ) : null}
            </Stack>
            {this.state.fxnType === "jrnyNFTList" ? (
              <LoadingButton
                disabled={this.state.dataArr.length === 10000}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={this.state.isFetching}
                style={{ marginTop: "25px" }}
                onClick={() => this.getNFTjourneyDetails()}
              >
                {`${this.state.dataArr.length}/10,000 fetched`}
              </LoadingButton>
            ) : null}
            {/* onClick={() => this.setState({ fxnType: "totalCSV" })} */}
            {this.state.fxnType === "totalCSV" ? (
              <LoadingButton
                disabled={this.state.dataArr.length}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={this.state.isFetching}
                style={{ marginTop: "25px" }}
                onClick={() => this.getTotalSnapshotCSV()}
              >
                {this.state.totalCSVData.length
                  ? "Fetched Successfull"
                  : "Get Total CSV"}
              </LoadingButton>
            ) : null}

            {this.state.fxnType === "add_snapshotData" ? (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={this.state.isLoading}
                onClick={() => this.addSnapshotData()}
                style={{ marginTop: "25px" }}
              >
                Add Snapshot Data
              </LoadingButton>
            ) : null}

            {this.state.fxnType === "totalCSV" ? (
              <CSVLink
                filename={`totalSnapshotCSV.csv`}
                data={this.state.csvData}
                headers={this.state.totalCSVheaders}
                target="_blank"
                style={{ marginTop: "25px" }}
                className="css-1iewgp6-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root"
                onClick={(event, done) => {
                  this.makeTotalCSV(this.state.totalCSVData);
                  //   done(); // Don't Proceed
                }}
              >
                <Icon
                  icon={CloudDownloadOutline}
                  style={{ fontSize: "20px" }}
                />{" "}
                &nbsp;Download CSV
              </CSVLink>
            ) : null}

            {/* {this.state.csvData.length ? ( */}
            {this.state.fxnType === "jrnyNFTList" ? (
              <CSVLink
                filename={`${this.state.igoname}.csv`}
                data={this.state.csvData}
                headers={this.state.headers}
                target="_blank"
                style={{ marginTop: "25px" }}
                className="css-1iewgp6-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root"
                onClick={(event, done) => {
                  this.makeCSV();
                  //   done(); // Don't Proceed
                }}
              >
                <Icon
                  icon={CloudDownloadOutline}
                  style={{ fontSize: "20px" }}
                />{" "}
                &nbsp;Download CSV
              </CSVLink>
            ) : null}
            {/* ) : null} */}
            {/* </form> */}
          </Card>
        </Container>
      </Page>
    );
  }
}
export default Snftsnapshot;
const ButtonList = styled.div`
  display: flex;
  align-items: center;
  Button {
    margin: 0px 5px;
    &.active {
      background-color: #048cfc;
    }
  }
`;
