import * as React from "react";
// import { filter } from 'lodash';
import { Icon } from "@iconify/react";
// import { sentenceCase } from 'change-case';
import { useState, useEffect } from "react";
import copyOutline from "@iconify/icons-eva/copy-outline";
import plusFill from "@iconify/icons-eva/plus-fill";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Link as RouterLink, useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { compactAddress } from "../contractData/function";
import { toaster } from "../utils/toast";
import ArrowDownFill from "@iconify/icons-eva/arrow-down-fill";
import Collapse from "@kunukn/react-collapse";

import { web3 } from "../web3";

import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
} from "@mui/material";

import Page from "../components/Page";
// import Label from '../components/Label';
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { UserListHead, UserMoreMenu } from "../components/_dashboard/user";
import USERLIST from "../_mocks_/user";
import MetamaskImg from "../assets/mm.png";
import { services } from "../services";
// import { service } from '../../services/web3.service';
import moment from "moment";
import { TimeStampToDateString } from "src/contractData/function";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { set } from "lodash";
// ----------------------------------------------------------------------
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "30px",
  boxShadow: 24,
  p: 4,
};

const TABLE_HEAD = [
  { id: "srNo", label: "Sr No", alignRight: false },
  { id: "projectName", label: "Project Name", alignRight: false },
  { id: "projectWebsiteName", label: "Project Website", alignRight: false },
  { id: "ownerName", label: "Owner Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  {
    id: "telegram",
    label: "Telegram Username",
    alignRight: false,
  },
];

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(
//       array,
//       (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
//     );
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export default function ApproveProjects() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState("");
  const [order, setOrder] = useState("asc");
  // const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  // const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [claim, setClaim] = useState([]);
  const [deployedPools, setDeployedPools] = useState([]);
  const [selectedBtn, setSelectedBtn] = useState("alreadyUpdated");
  const [web3Data, setWeb3Data] = useState({
    isLoggedIn: false,
    accounts: [],
  });
  const [adminSuggestion, setAdminSuggestion] = useState("");
  const [adminActionModal, setAdminActionModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedDropdownBtn, setSelectedDropdownBtn] = useState("ALL");

  const [projectsForApproval, setProjectsForApproval] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // connect metamask
  const connectMetamask = async () => {
    try {
      const getAcountDetails = await services.enableMetamask();
      setWeb3Data(getAcountDetails);
      if (getAcountDetails.isLoggedIn) {
        navigate("/dashboard/addclaimpools");
      }
    } catch (err) {
      console.log("error is:", err);
    }
  };

  const navigateToPage = (route) => {
    console.log("route is:", route);
    navigate(route, { replace: true });
  };

  useEffect(() => {
    callDeployedPools(page + 1);
    // selectedDropdownBtn("ALL");
  }, [page]);

  const isUserNotFound = projectsForApproval.length === 0;

  const callDeployedPools = async (page) => {
    let url = `https://raiserapi.blocsys.com/api/v1/apply-proj/list`;
    const sendRequest = await services.Get(url);
    if (sendRequest.status === 200) {
      let data = sendRequest.data.data;
      console.log("sendRequest", data);
      setProjectsForApproval(data);
      //   checkIsConnected();
      window.scrollTo(0, 0);
    }
  };
  const isActive = (btn) => {
    if (btn === selectedBtn) return "active";
  };
  const adminAction = async (action) => {
    const url = `https://raiserapi.blocsys.com/api/v1/apply-proj/update/${projectsForApproval[selectedProjectId].id}`;
    const payload = { approvalStatus: action, reason: adminSuggestion };
    const sendRequest = await services.Put(url, payload);
    if (sendRequest.status === 200) {
      return toaster(`${"IGO Status Updated Successfully"}`, "success");
      setAdminActionModal(false);
    }
  };

  const copyToClipboard = (address) => {
    copy(address);
    return toaster(`${"Copied!"}`, "success");
  };

  const [isOpen1, setIsOpen1] = React.useState(false);

  const onInit = () => {
    setIsOpen1(false);
  };

  return (
    <Page title="Manage Claims | Raiser Snapshot">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Approve Projects requests
          </Typography>
        </Stack>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <h3 style={{ textAlign: "center" }}>
                Please connect to your Wallet
              </h3>
              <MetamaskBTN
                onClick={() => {
                  connectMetamask();
                }}
              >
                <img src={MetamaskImg} alt="" /> Connect Metamask
              </MetamaskBTN>
            </Typography>
          </Box>
        </Modal>
        <Card style={{backgroundColor: '#151517'}}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {projectsForApproval.map((row, index) => {
                    const {
                      _id,
                      projectName,
                      projectWebsiteLink,
                      firstName,
                      email,
                      telegramUsername,
                    } = row;
                    console.log(row);
                    return (
                      <TableRow hover key={_id} tabIndex={-1}>
                        <TableCell
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                          spacing={2}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {projectName}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {projectWebsiteLink}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                        >
                          {compactAddress(firstName)}
                        </TableCell>
                        <TableCell
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                          align="left"
                        >
                          {" "}
                          {email}{" "}
                          <span
                            style={{
                              position: "relative",
                              top: "4px",
                              cursor: "pointer",
                            }}
                          >
                            <Icon
                              icon={copyOutline}
                              onClick={() => copyToClipboard(email)}
                              height={18}
                              width={18}
                            />
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ padding: "14px", whiteSpace: "nowrap" }}
                          align="left"
                        >
                          {" "}
                          {telegramUsername}{" "}
                          <span
                            style={{
                              position: "relative",
                              top: "4px",
                              cursor: "pointer",
                            }}
                          >
                            <Icon
                              icon={copyOutline}
                              onClick={() => copyToClipboard(telegramUsername)}
                              height={18}
                              width={18}
                            />
                          </span>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            to=""
                            onClick={() => {
                              setAdminActionModal(true);
                              setSelectedProjectId(index);
                            }}
                            // startIcon={<Icon icon={plusFill} />}
                            sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)" }}
                          >
                            Action
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <LoaderOuter>
                          <div className="loader"></div>
                        </LoaderOuter>
                        <SearchNotFound />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <Modal
            open={adminActionModal}
            onClose={() => setAdminActionModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{backgroundColor: '#151517'}}>
              <>
                <h3 style={{ marginBottom: "25px" }}>Review comments</h3>
                <TextField
                  fullWidth
                  value={adminSuggestion}
                  type="text"
                  multiline
                  label="Add your review comments here"
                  style={{ marginBottom: "25px" }}
                  onChange={(e) => setAdminSuggestion(e.target.value)}
                />
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    adminAction("approved");
                  }}
                >
                  Approve
                  {/* ) : null} */}
                </LoadingButton>{" "}
                <br></br>
                <br></br>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    adminAction("rejected");
                  }}
                >
                  Reject
                  {/* ) : null} */}
                </LoadingButton>
              </>
            </Box>
          </Modal>

          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={+totalPage}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}

const CustomSwitch = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #FBAE48;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #FBAE48;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
const ParentField = styled.div`
  position: relative;
  button {
    position: absolute;
    right: 10px;
    top: calc(50% - 20px);
    color: #fff;
    background: linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%);
    border-radius: 10px;
    padding: 10px 22px;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    font-family: Public Sans, sans-serif;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
`;
const LoaderOuter = styled.div`
  dispaly: flex;
  align-items: center;
  justify-content: center;
  .loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #5bb3fc;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin: 20px auto;
    animation: spin 0.5s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const ButtonList = styled.div`
  display: flex;
  align-items: center;
  Button {
    margin: 0px 5px;
    &.active {
      background-color: #048cfc;
    }
  }
`;

const MetamaskBTN = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  padding: 10px 30px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  img {
    width: 45px;
    margin-right: 10px;
  }
`;

const CompletedBox = styled.div`
  position: relative;
  .collapse-css-transition {
    position: absolute;
    top: 22px;
    right: 0px;
    left: auto;
    z-index: 9;
    width: 200px;
    transition: height 252ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  li {
    padding: 10px;
    cursor: pointer;
  }
`;
