import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CONTRACTADDRESS from "../config/contractData";
import {
  getContractInstanceAbi,
  convertToWei,
  getTokenInstance,
} from "../helper/contractHelper";
import CONSTANT from "../constant";
// material
import { web3Services } from "../services/web3.service";
import { toaster } from "../utils/toast";
import { Card, Stack, TextField, Container, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import moment from "moment";
import * as XLSX from "xlsx";
import { services } from "../services/index";
// ----------------------------------------------------------------------

class Claim extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      chains: [],
      selectedChain: "",
      selectedFile: null,
      isLoading: false,
    };
  }

  componentDidMount = () => {
    this.getChainList();
  };

  // get chain list
  getChainList = async () => {
    const url = `/network/list`;
    const getNetworkList = await services.Get(url);

    if (getNetworkList.status === 200) {
      this.setState({ chains: getNetworkList.data.data });
    }
  };

  // read a file
  readFile = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  // submit the form to server
  onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    this.setState({ isLoading: true });
    // Update the formData object
    formData.append(
      "csv",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    formData.append("walletId", this.state.selectedChain);

    const url = `/user/secondaryWallet`;

    const sendRequest = await services.Post(url, formData);

    if (sendRequest.status === 200) {
      this.setState({ isLoading: false });
      return toaster(`Your request have been received`, "success");
    } else {
      this.setState({ isLoading: false });
    }
  };
  render() {
    return (
      <Page title="Generate Report | Raiser Snapshot">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Generate Report
            </Typography>
          </Stack>

          <Card style={{ padding: "20px", backgroundColor: '#151517' }}>
            <form onSubmit={this.onSubmit}>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select chain
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value=""
                    label="Select Chain"
                    onChange={(e) => {
                      this.setState({
                        selectedChain: e.target.value,
                      });
                    }}
                    value={this.state.selectedChain}
                    required
                  >
                    <MenuItem value={""} disabled style={{backgroundColor: '#151517'}}>
                      Select Chain
                    </MenuItem>
                    {this.state.chains.map((networks) => (
                      <MenuItem value={`${networks._id}`} style={{backgroundColor: '#151517'}}>
                        {networks.networkName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField 
                  fullWidth
                  autoComplete="file"
                  type="file"
                  id="claimFile"
                  accept=".csv"
                  onChange={(e) => {
                    this.readFile(e);
                  }}
                  placeholder={"Upload File in format of csv"}
                  required
                />
              </Stack>
              <LoadingButton 
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={this.state.isLoading}
                style={{ marginTop: "25px", color: "#000000" }}
                sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)" }}
              >
                Submit
              </LoadingButton>
            </form>
          </Card>
        </Container>
      </Page>
    );
  }
}
export default Claim;
