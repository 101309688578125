import { toast } from 'react-toastify';

export function toaster(text, type) {
  //   return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');

  if (type === 'error') {
    return toast.error(`${text}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      timer: null,
    });
  } else if (type === 'warn') {
    return toast.warn(`${text}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      timer: null,
    });
  } else if (type === 'success') {
    return toast.success(`${text}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      timer: null,
    });
  } else {
    return toast(`${text}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      timer: null,
    });
  }
}
