import { walletConnectModalInit, web3 } from '../web3';
import Web3 from 'web3';
// import { Web } from "@material-ui/icons";

export async function getNetworkId() {
  try {
    return await web3.eth.getChainId();
  } catch (error) {
    return 1;
  }
}
export async function getWeb3(walletNo) {
  // if (walletNo === 2) return enableMathWallet();
  if (web3) {
    let web3Data = {
      isLoggedIn: false,
      accounts: [],
    };
    try {
      const responseData = await web3.eth.getAccounts();
      console.log(responseData);
      if (responseData.length) {
        web3Data.accounts = responseData;
        web3Data.isLoggedIn = true;
        return web3Data;
      } else {
        return web3Data;
      }
    } catch {
      return web3Data;
    }
  }
}
// export async function enabledWalletConnect() {
//   try {
//     await walletConnectModalInit();
//     const resp = await getWeb3();
//     return resp;
//   } catch (error) {
//     if (error.code === -32002) {
//       return {
//         isLoggedIn: false,
//         accounts: [],
//       };
//     }
//     return {
//       isLoggedIn: false,
//       accounts: [],
//     };
//   }
// }

export async function enableMetamask() {
  // let ethereum = window.ethereum;
  try {
    await window.ethereum.send('eth_requestAccounts');
    const resp = await getWeb3();
    return resp;
  } catch (error) {
    if (error.code === -32002) {
      return {
        isLoggedIn: false,
        accounts: [],
      };
    }
    return {
      isLoggedIn: false,
      accounts: [],
    };
  }
}

// export async function enableMathWallet() {
//   // let ethereum = window.ethereum;
//   try {
//     const accounts = await window.solana.getAccount();
//     return {
//       isLoggedIn: true,
//       accounts: [accounts],
//     };
//     console.log(accounts);
//     // window.solana
//     //   .getAccount()
//     //   .then((account) => {
//     //     console.log("this", {
//     //       isLoggedIn: true,
//     //       accounts: [account],
//     //     });
//     //     return {
//     //       isLoggedIn: true,
//     //       accounts: [account],
//     //     };
//     //   })
//     //   .catch(() => {
//     //     this.disconnect();
//     //     return {
//     //       isLoggedIn: false,
//     //       accounts: [],
//     //     };
//     //   });
//   } catch (error) {
//     return {
//       isLoggedIn: false,
//       accounts: [],
//     };
//   }
// }

export const networkProviders = {
//   binance: new Web3(
//     new Web3.providers.HttpProvider(
//       'https://data-seed-prebsc-1-s1.binance.org:8545/'
//     )
//   ),
//   polygon: new Web3('wss://ws-mumbai.matic.today'),
//   ethereum: new Web3(
//     new Web3.providers.HttpProvider(
//       'https://rinkeby.infura.io/v3/bef04cc587f548c8b1a3e76780722345'
//     )
//   ),
//   avalanche: new Web3(
//     new Web3.providers.HttpProvider(
//       'https://api.avax-test.network/ext/bc/C/rpc'
//     )
//   ),
// };


binance: new Web3(
  new Web3.providers.HttpProvider(
    'https://rpc.ankr.com/bsc_testnet_chapel'
  )
),
polygon: new Web3('https://rpc.ankr.com/polygon_mumbai'),
ethereum: new Web3(
  new Web3.providers.HttpProvider(
    'https://rpc.ankr.com/eth_goerli'
  )
),
avalanche: new Web3(
  new Web3.providers.HttpProvider(
    'https://api.avax-test.network/ext/bc/C/rpc'
  )
),
};


export const networkConfigs = {
  // ethereum: {
  //   chainId: "0x38",
  //   chainName: "Binance Smart Chain",
  //   nativeCurrency: {
  //     name: "Binance Chain Token",
  //     symbol: "BNB",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://bsc-dataseed2.binance.org/"],
  // },
//   binance: {
//     chainId: '0x38',
//     chainName: 'Binance Smart Chain',
//     nativeCurrency: {
//       name: 'Binance Chain Token',
//       symbol: 'BNB',
//       decimals: 18,
//     },
//     rpcUrls: ['https://bsc-dataseed2.binance.org/'],
//     blockExplorerUrls: ['https://bscscan.com/'],
//   },
//   polygon: {
//     chainId: '0x89',
//     chainName: 'Polygon Mainnet',
//     nativeCurrency: {
//       name: 'Polygon Mainnet',
//       symbol: 'MATIC',
//       decimals: 18,
//     },
//     rpcUrls: ['https://polygon-rpc.com/'],
//     blockExplorerUrls: ['https://polygonscan.com/'],
//   },
//   avalanche: {
//     chainId: '0xA86A',
//     chainName: 'Avalanche Mainnet C-Chain',
//     nativeCurrency: {
//       name: 'Avalanche Mainnet C-Chain',
//       symbol: 'AVAX',
//       decimals: 18,
//     },
//     rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
//     blockExplorerUrls: ['https://cchain.explorer.avax.network/'],
//   },
// };

binance: {
  chainId: '0x61',
  chainName: 'Binance Smart Chain',
  nativeCurrency: {
    name: 'Binance Chain Token',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: ['https://rpc.ankr.com/bsc_testnet_chapel'],
  blockExplorerUrls: ['https://bscscan.com/'],
},
polygon: {
  chainId: '0x13881',
  chainName: 'Polygon Testnet',
  nativeCurrency: {
    name: 'Polygon Mainnet',
    symbol: 'MATIC',
    decimals: 18,
  },
  rpcUrls: ['https://rpc.ankr.com/polygon_mumbai'],
  blockExplorerUrls: ['https://polygonscan.com/'],
},
avalanche: {
  chainId: '0xA869',
  chainName: 'Avalanche Testnet C-Chain',
  nativeCurrency: {
    name: 'Avalanche Testnet C-Chain',
    symbol: 'AVAX',
    decimals: 18,
  },
  rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
  blockExplorerUrls: ['https://cchain.explorer.avax.network/'],
},
};
