import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter,
}));

// const IconWrapperStyle = styled('div')(({ theme }) => ({
//   margin: 'auto',
//   display: 'flex',
//   borderRadius: '50%',
//   alignItems: 'center',
//   width: theme.spacing(8),
//   height: theme.spacing(8),
//   justifyContent: 'center',
//   marginBottom: theme.spacing(3),
//   color: theme.palette.info.dark,
//   backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
//     theme.palette.info.dark,
//     0.24
//   )} 100%)`
// }));

// ----------------------------------------------------------------------

// const TOTAL = 1352831;

export default function AppVerifiedUsers(props) {
  return (
    <RootStyle>
      <Typography variant='h3' sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent" }} >{fShortenNumber(props.count)}</Typography>
      <Typography variant='subtitle2' sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent" }} >
        {props.name}
      </Typography>
    </RootStyle>
  );
}
