import { services } from '../services';
let networkId = 1;
async function fetchNetworkId() {
  networkId = await services.getNetworkId();
}
fetchNetworkId();

function getContractAddresses() {
  if (networkId === '0x4' || +networkId === 4)
    return {
      contractAddress: '0x6a17f7C39034A0838A2c3C9ad72E7BafA0e497B9',
    };
  else if (+networkId === 1 || networkId === '0x1')
    return {
      contractAddress: '0x6a17f7C39034A0838A2c3C9ad72E7BafA0e497B9',
    };
  else
    return {
      contractAddress: '0x6a17f7C39034A0838A2c3C9ad72E7BafA0e497B9',
    };
}
export default getContractAddresses;
