import axios from "axios";
import CONSTANT from "src/constant";

// production server
// export default axios.create({
//   baseURL: 'http://localhost:4000/api/v1',
// });
// local;

export default axios.create({
  baseURL:
    // CONSTANT.ENV === "DEVELOPMENT"
    //   ? "https://m22api.minddeft.com/api/v1"
    //   : "https://m22api.minddeft.com/api/v1",


    // CONSTANT.ENV === "DEVELOPMENT"
    //   ? "http://localhost:5000/api/v1"
    //   : "http://localhost:5000/api/v1",

    CONSTANT.ENV === "DEVELOPMENT"
      ? "https://raiserapi.blocsys.com/api/v1"
      : "https://raiserapi.blocsys.com/api/v1",

});
