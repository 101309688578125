import LotteryAbi from "../abi/contract.json";
import ClaimAbi from "../abi/claim.json";
import accumulationABI from "../abi/accumulationABI.json";
import linearConractABI from "../abi/lenearVesting.json";
import getContractAddresses from "../config/address";
import TokenAbi from "../abi/token.json";
import { web3 } from "../web3";
import IGOABI from "../abi/igo.json";

export function getContractInstance() {
  const { contractAddress } = getContractAddresses();

  const currentABI = LotteryAbi;
  try {
    if (web3) {
      const contractInstance = new web3.eth.Contract(
        currentABI,
        contractAddress
      );
      return contractInstance;
    }
  } catch (error) {}
}

export function getContractInstanceAbi(contractAddress, contractType) {
  let currentABI =
    contractType === "igo"
      ? IGOABI
      : contractType === "merkle"
      ? accumulationABI
      : contractType === "linear"
      ? linearConractABI
      : ClaimAbi;
  // const currentABI = isLinear ? linearConractABI : accumulationABI;
  try {
    if (web3) {
      const contractInstance = new web3.eth.Contract(
        currentABI,
        contractAddress
      );
      return contractInstance;
    }
  } catch (error) {}
}

export function getTokenInstance(contractAddress) {
  const currentABI = TokenAbi;
  try {
    if (web3) {
      const contractInstance = new web3.eth.Contract(
        currentABI,
        contractAddress
      );
      return contractInstance;
    }
  } catch (error) {}
}

export function convertToWei(number) {
  if (number) {
    return web3.utils.toWei(number);
  } else {
    return 0;
  }
}
