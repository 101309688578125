import { Navigate, useRoutes, useNavigate } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import DashboardApp from "./pages/DashboardApp";
import ManageClaims from "./pages/ManageClaims";
import GenerateSnapshot from "./pages/GenerateSnapshot";
import Pools from "./pages/Pools";
import User from "./pages/User";
import AddClaimPools from "./pages/AddClaimPools";
import AddPools from "./pages/AddPools";
import GenerateTierList from "./pages/GenerateTierList";
import GenerateRequestNumber from "./pages/GenerateRequestNumber";
import GenerateLottery from "./pages/GenerateLottery";
import Network from "./pages/Network";
import GenerateReport from "./pages/GenerateReport";
import GenerateCSV from "./pages/GenerateCSV";
import CheckLogs from "./pages/CheckLogs";
import Snftsnapshot from "./pages/snftSnapshot";
import AddSolanaPools from "./pages/AddSolanaPools";
import HistorialSnapshot from "./pages/historicalSnapshot";
import AddIgo from "./pages/AddIgo";
import ManageIgo from "./pages/ManageIgo";
import ApproveProjects from "./pages/ApproveProjects";
import { id } from "date-fns/locale";

// ----------------------------------------------------------------------

export default function Router() {
  const navigate = useNavigate();
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: "app", element: <DashboardApp /> },
        { path: "user", element: <User /> },
        { path: "manageclaims", element: <ManageClaims /> },
        { path: "generatesnapshot", element: <GenerateSnapshot /> },
        { path: "pools", element: <Pools /> },
        { path: "generatereport", element: <GenerateReport /> },
        {
          path: "addclaimpools",
          element: <AddClaimPools navigate={navigate} />,
        },
        {
          path: "editCompletedClaimpools/:id",
          element: <AddClaimPools navigate={navigate} isCompleted={true} />,
        },
        {
          path: "editPendingClaimpools/:id",
          element: <AddClaimPools navigate={navigate} />,
        },
        { path: "addpools", element: <AddPools navigate={navigate} /> },
        { path: "editPools/:id", element: <AddPools navigate={navigate} /> },
        { path: "generatetierlist", element: <GenerateTierList /> },
        { path: "generaterequestnumber", element: <GenerateRequestNumber /> },
        { path: "generatelottery", element: <GenerateLottery /> },
        { path: "network", element: <Network /> },
        { path: "generatecsv", element: <GenerateCSV /> },
        { path: "checkLogs", element: <CheckLogs /> },
        { path: "snapshotFunctions", element: <Snftsnapshot /> },
        { path: "addSolanaPool", element: <AddSolanaPools /> },
        { path: "approveProjects", element: <ApproveProjects /> },
        {
          path: "editCompletedAddSolanapools/:id",
          element: <AddSolanaPools navigate={navigate} isCompleted={true} />,
        },
        {
          path: "editPendingAddSolanapools/:id",
          element: <AddSolanaPools navigate={navigate} />,
        },
        { path: "historicalSnapshotFunctions", element: <HistorialSnapshot /> },
        { path: "addIgo", element: <AddIgo /> },
        { path: "manageIgo", element: <ManageIgo /> },
        {
          path: "editIgoPool/:id",
          element: <AddIgo navigate={navigate} editIgo={true} />,
        },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [{ path: "/", element: <Login /> }],
    },
  ]);
}
